// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support_buymoreBtn__cSF98 {
   
    height: 3.125rem;
    flex-shrink: 0;
    width: 100%;
   
}

.support_btnText__4hi7b {
    color: #212121;
    text-align: center;
    font-family: Aileron;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
}
.support_faqDiv__3NCEZ{
    max-height: 580px;
    overflow-y: scroll;
}
@media screen  and (max-width: 767px)  {
    .support_buymoreBtn__cSF98{
        display: none;
    } 
    .support_faqDiv__3NCEZ{
        max-height: 380px;
        overflow-y: scroll;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/SupportComponent/support.module.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;IAChB,cAAc;IACd,WAAW;;AAEf;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;;AAEvB;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI;QACI,aAAa;IACjB;IACA;QACI,iBAAiB;QACjB,kBAAkB;IACtB;AACJ","sourcesContent":[".buymoreBtn {\n   \n    height: 3.125rem;\n    flex-shrink: 0;\n    width: 100%;\n   \n}\n\n.btnText {\n    color: #212121;\n    text-align: center;\n    font-family: Aileron;\n    font-size: 1rem;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n    \n}\n.faqDiv{\n    max-height: 580px;\n    overflow-y: scroll;\n}\n@media screen  and (max-width: 767px)  {\n    .buymoreBtn{\n        display: none;\n    } \n    .faqDiv{\n        max-height: 380px;\n        overflow-y: scroll;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buymoreBtn": `support_buymoreBtn__cSF98`,
	"btnText": `support_btnText__4hi7b`,
	"faqDiv": `support_faqDiv__3NCEZ`
};
export default ___CSS_LOADER_EXPORT___;
