// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alerts {
  background: #fff !important;
  border-radius: 20px;
  display: flex;
  width: 520px;
  padding: 20px 16px 32px 50px;
  align-items: flex-start;
  gap: 38px;
}

.success-alert {
  /* border: 1px solid #112750 !important; */
  color: #212121;
  z-index: 1111;
  border-left: 20px solid #008b3c !important;

  background: #fff;
}

.error-alert {
  color: #212121;
  border-left: 20px solid #ce0809 !important;
  z-index: 1111 !important;
}
.top-5 {
  margin-top: 5rem;
}
.cursor-pointer {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/utils/customToast/custom-alert.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,4BAA4B;EAC5B,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,0CAA0C;EAC1C,cAAc;EACd,aAAa;EACb,0CAA0C;;EAE1C,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,0CAA0C;EAC1C,wBAAwB;AAC1B;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB","sourcesContent":[".alerts {\n  background: #fff !important;\n  border-radius: 20px;\n  display: flex;\n  width: 520px;\n  padding: 20px 16px 32px 50px;\n  align-items: flex-start;\n  gap: 38px;\n}\n\n.success-alert {\n  /* border: 1px solid #112750 !important; */\n  color: #212121;\n  z-index: 1111;\n  border-left: 20px solid #008b3c !important;\n\n  background: #fff;\n}\n\n.error-alert {\n  color: #212121;\n  border-left: 20px solid #ce0809 !important;\n  z-index: 1111 !important;\n}\n.top-5 {\n  margin-top: 5rem;\n}\n.cursor-pointer {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
