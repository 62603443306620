// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.passwordChangedSuccess_MainDiv__4OAJo {
    width: 500px;
    height: 289px;
    margin: 0 auto;
    /* background-color: red; */
    display: grid;
  }
  
  .passwordChangedSuccess_SuccessIcon__rHqea {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    margin: auto;
  }
  
  .passwordChangedSuccess_heading__BAVBz {
    /* width: 272px; */
    height: 44px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  
  .passwordChangedSuccess_subHeading__uk-sE {
    /* width: 188px; */
    height: 30px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  
  .passwordChangedSuccess_ButtonText__L3AhG {
    width: 474.194px;
    height: 20px;
    flex-shrink: 0;
    color: #212121;
    text-align: center;
    font-family: Aileron;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/PasswordChangedSuccessComponent/passwordChangedSuccess.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,2BAA2B;IAC3B,aAAa;EACf;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,cAAc;IACd,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,oBAAoB;IACpB,eAAe;IACf,mBAAmB;IACnB,oBAAoB;IACpB,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;EACrB","sourcesContent":[".MainDiv {\n    width: 500px;\n    height: 289px;\n    margin: 0 auto;\n    /* background-color: red; */\n    display: grid;\n  }\n  \n  .SuccessIcon {\n    width: 80px;\n    height: 80px;\n    flex-shrink: 0;\n    margin: auto;\n  }\n  \n  .heading {\n    /* width: 272px; */\n    height: 44px;\n    flex-grow: 0;\n    font-family: Poppins;\n    font-size: 36px;\n    font-weight: 600;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: 1.22;\n    letter-spacing: normal;\n    text-align: center;\n    color: #fff;\n  }\n  \n  .subHeading {\n    /* width: 188px; */\n    height: 30px;\n    flex-grow: 0;\n    font-family: Poppins;\n    font-size: 16px;\n    font-weight: normal;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: 1.82;\n    letter-spacing: normal;\n    text-align: center;\n    color: #fff;\n  }\n  \n  .ButtonText {\n    width: 474.194px;\n    height: 20px;\n    flex-shrink: 0;\n    color: #212121;\n    text-align: center;\n    font-family: Aileron;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainDiv": `passwordChangedSuccess_MainDiv__4OAJo`,
	"SuccessIcon": `passwordChangedSuccess_SuccessIcon__rHqea`,
	"heading": `passwordChangedSuccess_heading__BAVBz`,
	"subHeading": `passwordChangedSuccess_subHeading__uk-sE`,
	"ButtonText": `passwordChangedSuccess_ButtonText__L3AhG`
};
export default ___CSS_LOADER_EXPORT___;
