// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SuspendLoader_container__K5FDB {
  width: 540px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #957136; /* Background color */
  z-index: 999;
  border-radius: 10px;
  opacity: 0.9;
  padding: 60px 40px 60px 40px;
  display: flex;
  align-items: center;
  flex-direction: column;

  justify-content: center;
}

.SuspendLoader_header1__HkRbr {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.SuspendLoader_header2__ibGJM {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Common/SuspendLoader/SuspendLoader.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,yBAAyB,EAAE,qBAAqB;EAChD,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,4BAA4B;EAC5B,aAAa;EACb,mBAAmB;EACnB,sBAAsB;;EAEtB,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".container {\n  width: 540px;\n  height: 300px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: #957136; /* Background color */\n  z-index: 999;\n  border-radius: 10px;\n  opacity: 0.9;\n  padding: 60px 40px 60px 40px;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n\n  justify-content: center;\n}\n\n.header1 {\n  font-family: Poppins;\n  font-size: 30px;\n  font-weight: 600;\n  color: #fff;\n}\n.header2 {\n  font-family: Poppins;\n  font-size: 16px;\n  font-weight: 500;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SuspendLoader_container__K5FDB`,
	"header1": `SuspendLoader_header1__HkRbr`,
	"header2": `SuspendLoader_header2__ibGJM`
};
export default ___CSS_LOADER_EXPORT___;
