// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_LayoutContainer__ad5Vl {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* align-self: center; */
  text-align: center;
  /* width: 100%; */
  /* height: 100vh; */
  /* padding: 0 10px; */
  /* gap: 15px; */
  flex-wrap: nowrap;
}

/* .SideBarContainer {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: unset;
  flex: 15%;
  height: 100vh;
  padding: 10px;
  padding: 15px;
  border-radius: 15px;
  background: rgba(55, 47, 141, 0.5);
  margin-top: 15px;
  max-height: 90vh;
} */

/* .OppositeSidebar {
  display: flex;
  flex: 100%;
  flex-direction: column;
  padding: 15px;
  border-radius: 15px;
  background: rgba(55, 47, 141, 0.5);
  margin-top: 15px;
} */

.Layout_PageBody__NGu4D {
  overflow: auto;
  /* top: 80px; */
  /* position: absolute; */
  width: -moz-available;
  /* height: 2000px; */
  /* padding: 0em 2em 2em 2em; */
}

@media screen and (max-width: 769px) {
  .Layout_PageBody__NGu4D {
    padding: 0em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Layouts/Layout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,yBAAyB;EACzB,wBAAwB;EACxB,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;EACnB,qBAAqB;EACrB,eAAe;EACf,iBAAiB;AACnB;;AAEA;;;;;;;;;;;;;;;GAeG;;AAEH;;;;;;;;GAQG;;AAEH;EACE,cAAc;EACd,eAAe;EACf,wBAAwB;EACxB,qBAAqB;EACrB,oBAAoB;EACpB,8BAA8B;AAChC;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".LayoutContainer {\n  display: flex;\n  flex-direction: column;\n  /* justify-content: center; */\n  /* align-items: center; */\n  /* align-self: center; */\n  text-align: center;\n  /* width: 100%; */\n  /* height: 100vh; */\n  /* padding: 0 10px; */\n  /* gap: 15px; */\n  flex-wrap: nowrap;\n}\n\n/* .SideBarContainer {\n  top: 0;\n  position: -webkit-sticky;\n  position: sticky;\n  display: flex;\n  flex-direction: column;\n  gap: unset;\n  flex: 15%;\n  height: 100vh;\n  padding: 10px;\n  padding: 15px;\n  border-radius: 15px;\n  background: rgba(55, 47, 141, 0.5);\n  margin-top: 15px;\n  max-height: 90vh;\n} */\n\n/* .OppositeSidebar {\n  display: flex;\n  flex: 100%;\n  flex-direction: column;\n  padding: 15px;\n  border-radius: 15px;\n  background: rgba(55, 47, 141, 0.5);\n  margin-top: 15px;\n} */\n\n.PageBody {\n  overflow: auto;\n  /* top: 80px; */\n  /* position: absolute; */\n  width: -moz-available;\n  /* height: 2000px; */\n  /* padding: 0em 2em 2em 2em; */\n}\n\n@media screen and (max-width: 769px) {\n  .PageBody {\n    padding: 0em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LayoutContainer": `Layout_LayoutContainer__ad5Vl`,
	"PageBody": `Layout_PageBody__NGu4D`
};
export default ___CSS_LOADER_EXPORT___;
