// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.money_tokenContainer__3Zm5L {
    min-height: 13em;
    border-radius: 10px;
    background: #e8c056;
}
.money_tokenHeading__UKVWx{
    font-family: Poppins;
    font-size: 3em;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    word-wrap: break-word;

}
.money_cardImg__GBVrc{
    aspect-ratio: 8/4;
    object-fit: contain;

}


@media screen and (max-width: 767px){
    .money_tokenHeading__UKVWx{       
        font-size: 1.5em;
        font-weight: 600;    
    }
}
@media screen  and (max-width: 480px) {
    .money_tokenHeading__UKVWx{       
        font-size: 1.5em;
        font-weight: 600;    
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/MoneyComponent/money.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,oBAAoB;IACpB,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;;AAEzB;AACA;IACI,iBAAiB;IACjB,mBAAmB;;AAEvB;;;AAGA;IACI;QACI,gBAAgB;QAChB,gBAAgB;IACpB;AACJ;AACA;IACI;QACI,gBAAgB;QAChB,gBAAgB;IACpB;AACJ","sourcesContent":[".tokenContainer {\n    min-height: 13em;\n    border-radius: 10px;\n    background: #e8c056;\n}\n.tokenHeading{\n    font-family: Poppins;\n    font-size: 3em;\n    font-weight: 600;\n    letter-spacing: 0em;\n    text-align: left;\n    word-wrap: break-word;\n\n}\n.cardImg{\n    aspect-ratio: 8/4;\n    object-fit: contain;\n\n}\n\n\n@media screen and (max-width: 767px){\n    .tokenHeading{       \n        font-size: 1.5em;\n        font-weight: 600;    \n    }\n}\n@media screen  and (max-width: 480px) {\n    .tokenHeading{       \n        font-size: 1.5em;\n        font-weight: 600;    \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tokenContainer": `money_tokenContainer__3Zm5L`,
	"tokenHeading": `money_tokenHeading__UKVWx`,
	"cardImg": `money_cardImg__GBVrc`
};
export default ___CSS_LOADER_EXPORT___;
