// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SupportHeader_bannerContainer__eaN-G {
  background-color: #1d3118;
  border-radius: 20px;
  /* margin: 0.5em; */
  /* height: 20rem; */
}
.SupportHeader_header1__THvYR {
  color: #f4a537;
  font-family: Desire-Pro;
  font-size: 5em;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  text-align: left;
  /* letter-spacing: -2px; */
}
.SupportHeader_header2__kNSh6 {
  color: #fff;
  font-family: Aileron;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  /* align-items: flex-start; */
}
.SupportHeader_col1__UhMRk {
  text-align: left;
  padding: 3em !important;
}
.SupportHeader_bannerImage__5WOQM {
  /* height: 30rem; */
  width: 100%;
  border-radius: 20px;
}
.SupportHeader_col2__AGx5j {
  padding: 0px;
  text-align: right;
}

@media screen and (max-width: 767px) {
  .SupportHeader_bannerContainer__eaN-G {
    background-color: #fff;
  }

  .SupportHeader_header1__THvYR {
    color: #000000;
    font-family: Desire-Pro;
    font-size: 2.5rem;
  }

  .SupportHeader_header2__kNSh6 {
    color: #000000;
    font-family: Aileron;
    font-size: .8rem;
  }
  .SupportHeader_bannerImage__5WOQM {
    /* height: 30rem; */
    width: 100%;
    border-radius: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/SupportComponent/SupportHeader/SupportHeader.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,uBAAuB;EACvB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,0BAA0B;AAC5B;AACA;EACE,WAAW;EACX,oBAAoB;EACpB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,6BAA6B;AAC/B;AACA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,cAAc;IACd,uBAAuB;IACvB,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,oBAAoB;IACpB,gBAAgB;EAClB;EACA;IACE,mBAAmB;IACnB,WAAW;IACX,mBAAmB;EACrB;AACF","sourcesContent":[".bannerContainer {\n  background-color: #1d3118;\n  border-radius: 20px;\n  /* margin: 0.5em; */\n  /* height: 20rem; */\n}\n.header1 {\n  color: #f4a537;\n  font-family: Desire-Pro;\n  font-size: 5em;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 1em;\n  text-align: left;\n  /* letter-spacing: -2px; */\n}\n.header2 {\n  color: #fff;\n  font-family: Aileron;\n  font-size: 1.2em;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  text-align: left;\n  /* align-items: flex-start; */\n}\n.col1 {\n  text-align: left;\n  padding: 3em !important;\n}\n.bannerImage {\n  /* height: 30rem; */\n  width: 100%;\n  border-radius: 20px;\n}\n.col2 {\n  padding: 0px;\n  text-align: right;\n}\n\n@media screen and (max-width: 767px) {\n  .bannerContainer {\n    background-color: #fff;\n  }\n\n  .header1 {\n    color: #000000;\n    font-family: Desire-Pro;\n    font-size: 2.5rem;\n  }\n\n  .header2 {\n    color: #000000;\n    font-family: Aileron;\n    font-size: .8rem;\n  }\n  .bannerImage {\n    /* height: 30rem; */\n    width: 100%;\n    border-radius: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `SupportHeader_bannerContainer__eaN-G`,
	"header1": `SupportHeader_header1__THvYR`,
	"header2": `SupportHeader_header2__kNSh6`,
	"col1": `SupportHeader_col1__UhMRk`,
	"bannerImage": `SupportHeader_bannerImage__5WOQM`,
	"col2": `SupportHeader_col2__AGx5j`
};
export default ___CSS_LOADER_EXPORT___;
