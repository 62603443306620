// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page404_bannerContainer__DoK9s {
  border-radius: 10px;
  padding: 3em 7em;
  font-family: Poppins;
  min-height: 100vh;
}
.page404_header1__WKh7s {
  color: #f3a537;

  font-family: Poppins;
  font-size: 4em;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
}
.page404_header2__HNGDv {
  color: #fff;
  font-family: Poppins;
  font-size: 4em;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
}
.page404_subHeader1__PJ10s {
  color: #fff;
  font-family: Poppins;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
}
.page404_col1__nIddb {
  /* margin-left: 1em; */
}
.page404_bannerImage__TupD6 {
  height: 390px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Page404Component/page404.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,oBAAoB;EACpB,iBAAiB;AACnB;AACA;EACE,cAAc;;EAEd,oBAAoB;EACpB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,oBAAoB;EACpB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,oBAAoB;EACpB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,aAAa;AACf","sourcesContent":[".bannerContainer {\n  border-radius: 10px;\n  padding: 3em 7em;\n  font-family: Poppins;\n  min-height: 100vh;\n}\n.header1 {\n  color: #f3a537;\n\n  font-family: Poppins;\n  font-size: 4em;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 1em;\n}\n.header2 {\n  color: #fff;\n  font-family: Poppins;\n  font-size: 4em;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 1em;\n}\n.subHeader1 {\n  color: #fff;\n  font-family: Poppins;\n  font-size: 1em;\n  font-style: normal;\n  font-weight: 400;\n}\n.col1 {\n  /* margin-left: 1em; */\n}\n.bannerImage {\n  height: 390px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `page404_bannerContainer__DoK9s`,
	"header1": `page404_header1__WKh7s`,
	"header2": `page404_header2__HNGDv`,
	"subHeader1": `page404_subHeader1__PJ10s`,
	"col1": `page404_col1__nIddb`,
	"bannerImage": `page404_bannerImage__TupD6`
};
export default ___CSS_LOADER_EXPORT___;
