// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.getQuote_header__nsDkt {
  font-family: Poppins;
  font-weight: 700;
  font-size: 26px;
}
.getQuote_subheader__KKWIO {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/RedeemTokenListComponent/GetQuoteModal/getQuote.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".header {\n  font-family: Poppins;\n  font-weight: 700;\n  font-size: 26px;\n}\n.subheader {\n  font-family: Poppins;\n  font-weight: 400;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `getQuote_header__nsDkt`,
	"subheader": `getQuote_subheader__KKWIO`
};
export default ___CSS_LOADER_EXPORT___;
