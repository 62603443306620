//@ts-nocheck
import moment from "moment";
import notify from "../notify";

const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return accessToken;
};
const getUserId = () => {
  const userId = localStorage.getItem("user_id");
  return userId;
};
const getUserEmail = () => {
  const userEmail = localStorage.getItem("user_email");
  return userEmail;
};
const getTokenExpire = () => {
  const userTokenExpire = localStorage.getItem("token_expire");
  return userTokenExpire;
};
const getAWSToken = () => {
  const getToken = localStorage.getItem("aws_access_token");
  const data = getToken === null ? "" : getToken;
  return data;
};
const getAWSSession = () => {
  const getToken = localStorage.getItem("aws_session");
  const data = getToken === null ? "" : getToken;
  return data;
};
const getUserName = () => {
  const userName = localStorage.getItem("user_name");
  return userName;
};

const isObjectEmpty = (obj: Record<string, unknown>) => {
  if (Object.keys(obj).length === 0) return true;

  return !Object.values(obj).some((val) => Boolean(val));
};

const clearLocalStorage = () => {
  localStorage.clear();
};

const getKYCStatus = (val: any, request_addition_proof: 'required' | 'not_required' | 'in_review') => {

  if (request_addition_proof === 'required') val = 'Require_Docs';
  if (request_addition_proof === 'in_review') val = 'inreview';

  switch (val) {
    case "Approved":
      return "Verified";
    case "Rejected":
      return "Rejected";
    case "inreview":
      return "Pending";
    case "blocked":
      return "Pending";
    case 'Not_Eligible':
      return 'Not_Eligible';
    case 'Require_Docs':
      return 'Require_Docs';
    case 'init':
    case false:
      return "";
    default:
      return "Pending";
  }
};

function maskEmail(email: string) {
  // Split the email into local part and domain part
  const [localPart, domainPart] = email.split("@");

  // Mask the local part with asterisks, keeping the first and last characters
  const maskedLocalPart =
    localPart.length > 2
      ? localPart[0] + "*".repeat(localPart.length - 2) + localPart.slice(-1)
      : localPart;

  // Mask the domain part with asterisks, keeping the first and last characters
  const maskedDomainPart =
    domainPart.length > 2
      ? domainPart[0] + "*".repeat(domainPart.length - 2) + domainPart.slice(-1)
      : domainPart;

  // Combine the masked local and domain parts to form the masked email
  const maskedEmail = maskedLocalPart + "@" + maskedDomainPart;

  return maskedEmail;
}

const getMonthByNumberMap = () => {
  const data: { [key: string]: string } = {
    "1": "Jan",
    "2": "Feb",
    "3": "Mar",
    "4": "Apr",
    "5": "May",
    "6": " Jun",
    "7": "Jul",
    "8": "Aug",
    "9": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  };
  return data;
};

const copyToClipboard = (src: string): void => {
  if (navigator) navigator.clipboard.writeText(src);
};

const copyText = (val: string) => {
  notify.success("Copied Successfully");
  copyToClipboard(val);
};
const getShortName = (str: string, screen: boolean) => {
  if (screen) {
    if (str.length <= 100) {
      return str;
    } else {
      const firstPart = str.substring(0, 100);
      return firstPart + "...";
    }
  } else {
    if (str.length <= 10) {
      return str;
    } else {
      const partName = str.split(" ");
      if (partName[0].length <= 10) {
        return partName[0];
      } else {
        const firstPart = partName[0].substring(0, 10);
        return firstPart + "...";
      }
    }
  }
};
const getDateFormat = (val: any) => {
  const date = moment(val).format("ll");
  return date;
};

const removeBlackListCountry = (allCountry: any, blackListCountry: any) => {
  const clearList = allCountry.filter((country: any) => {
    return !blackListCountry.some(
      (blackCountry: any) => blackCountry.country_id === country.country_id
    );
  });
  return clearList;
};
const formatMoney = (number: number, field: string = "") => {
  if (field === "") {
    if (number >= 1000000000) {
      // For billions
      return (number / 1000000000).toFixed(1) + "B";
    } else if (number >= 1000000) {
      // For millions
      return (number / 1000000).toFixed(1) + "M";
    } else if (number >= 1000) {
      // For thousands
      return (number / 1000).toFixed(1) + "K";
    } else {
      // For less than a thousand, just return the number
      return number.toFixed(2).toString();
    }
  } else {
    return number.toFixed(GoldDecimal).toString();
  }
};
const convertToUint256 = (num: string): string => {
  if (isNaN(Number(num)) || Number(num) < 0) {
    return "Invalid input";
  }

  // Split the number into integer and decimal parts
  let [integerPart, decimalPart] = num.split(".");

  // Handle integer part
  let bigIntIntegerPart: bigint;
  try {
    bigIntIntegerPart = BigInt(integerPart);
  } catch (e) {
    return "Conversion error";
  }

  // Handle decimal part
  let bigIntDecimalPart: bigint;
  if (decimalPart) {
    bigIntDecimalPart =
      BigInt(decimalPart) * 10n ** BigInt(18 - decimalPart.length);
  } else {
    bigIntDecimalPart = 0n;
  }

  // Combine integer and decimal parts
  const combined = bigIntIntegerPart * 10n ** 18n + bigIntDecimalPart;

  // Ensure it's within the uint256 range
  const maxUint256 = 2n ** 256n - 1n;
  if (combined > maxUint256) {
    return "Number too large for uint256";
  }

  return combined.toString();
};
const GoldDecimal = 8;
const GetOrderStatus = (status: string) => {
  if (status === "placed") {
    return (
      <span className="px-3 py-2 badge bg-success  rounded text-uppercase">
        {status}
      </span>
    );
  } else {
    return (
      <span
        className="px-3 py-2 badge rounded text-uppercase"
        style={{ backgroundColor: "#8e810c" }}>
        {status}
      </span>
    );
  }
};
export {
  getAccessToken,
  clearLocalStorage,
  getUserId,
  getUserEmail,
  getKYCStatus,
  maskEmail,
  getMonthByNumberMap,
  copyText,
  getTokenExpire,
  getAWSSession,
  getAWSToken,
  getUserName,
  getShortName,
  getDateFormat,
  removeBlackListCountry,
  formatMoney,
  GoldDecimal,
  convertToUint256,
  GetOrderStatus,
  isObjectEmpty
};
