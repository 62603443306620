// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addMetamaskCard_card2__wlPuK {
  /* width: 413px; */
  height: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  border: solid 1px #a6a6a6;
  background-color: #00112b;
}

.addMetamaskCard_textHeading__5nJ1a {
  /* width: 201px; */
  /* height: 13px; */
  flex-grow: 0;
  /* margin: 0 82.6px 14px 0; */
  margin: 0;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
}

.addMetamaskCard_text__2Dg0D {
  /* width: 283.6px; */
  /* height: 54px; */
  flex-grow: 0;
  margin: 8px 0 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
}
.addMetamaskCard_labelText__n\\+KXn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.addMetamaskCard_contentBox__1nq86 {
  text-align: left;
}

.addMetamaskCard_bannerImage__aHMek {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/MyProfileComponent/AddMetamaskCard/addMetamaskCard.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,6BAA6B;EAC7B,SAAS;EACT,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,oBAAoB;EACpB,eAAe;EACf,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;EAChB,+BAA+B;AACjC;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".card2 {\n  /* width: 413px; */\n  height: 100%;\n  flex-grow: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  border-radius: 10px;\n  border: solid 1px #a6a6a6;\n  background-color: #00112b;\n}\n\n.textHeading {\n  /* width: 201px; */\n  /* height: 13px; */\n  flex-grow: 0;\n  /* margin: 0 82.6px 14px 0; */\n  margin: 0;\n  font-family: Poppins;\n  font-size: 24px;\n  font-weight: 700;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  text-align: left;\n  color: rgba(255, 255, 255, 0.8);\n}\n\n.text {\n  /* width: 283.6px; */\n  /* height: 54px; */\n  flex-grow: 0;\n  margin: 8px 0 0;\n  font-family: Poppins;\n  font-size: 14px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: 0.14px;\n  text-align: left;\n  color: rgba(255, 255, 255, 0.8);\n}\n.labelText {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.contentBox {\n  text-align: left;\n}\n\n.bannerImage {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card2": `addMetamaskCard_card2__wlPuK`,
	"textHeading": `addMetamaskCard_textHeading__5nJ1a`,
	"text": `addMetamaskCard_text__2Dg0D`,
	"labelText": `addMetamaskCard_labelText__n+KXn`,
	"contentBox": `addMetamaskCard_contentBox__1nq86`,
	"bannerImage": `addMetamaskCard_bannerImage__aHMek`
};
export default ___CSS_LOADER_EXPORT___;
