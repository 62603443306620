// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.greeting_greetingContainer__KMcqt {
  /* width: 27.75rem; */
  height: 3rem;
}

.greeting_welcomeText__7A6RD {
  color: #fff;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;

  /* 96% */
}

.greeting_nameText__09Mvc {
  background:  linear-gradient(rgba(232, 192, 86, 1) , rgba(156, 117, 26, 1),   rgba(232, 192, 86, 1)  , rgba(156, 117, 26, 1));
 
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

@media screen and (max-width: 767px) {
  .greeting_greetingContainer__KMcqt {
   padding-left: 10px;
   padding-top: 1rem;
   padding-bottom: 1rem;
   margin-bottom: 2.5rem;
  }
}

@media screen and (max-width: 426px) {
  .greeting_nameText__09Mvc, .greeting_welcomeText__7A6RD {
    font-size: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/GreetingComponent/greeting.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;;EAEhB,QAAQ;AACV;;AAEA;EACE,6HAA6H;;EAE7H,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;;EAEhB,oCAAoC;EACpC,6BAA6B;AAC/B;;AAEA;EACE;GACC,kBAAkB;GAClB,iBAAiB;GACjB,oBAAoB;GACpB,qBAAqB;EACtB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".greetingContainer {\n  /* width: 27.75rem; */\n  height: 3rem;\n}\n\n.welcomeText {\n  color: #fff;\n  font-family: Poppins;\n  font-size: 40px;\n  font-weight: 600;\n  line-height: 60px;\n  letter-spacing: 0em;\n  text-align: left;\n\n  /* 96% */\n}\n\n.nameText {\n  background:  linear-gradient(rgba(232, 192, 86, 1) , rgba(156, 117, 26, 1),   rgba(232, 192, 86, 1)  , rgba(156, 117, 26, 1));\n \n  font-family: Poppins;\n  font-size: 40px;\n  font-weight: 600;\n  line-height: 60px;\n  letter-spacing: 0em;\n  text-align: left;\n\n  -webkit-text-fill-color: transparent;\n  -webkit-background-clip: text;\n}\n\n@media screen and (max-width: 767px) {\n  .greetingContainer {\n   padding-left: 10px;\n   padding-top: 1rem;\n   padding-bottom: 1rem;\n   margin-bottom: 2.5rem;\n  }\n}\n\n@media screen and (max-width: 426px) {\n  .nameText, .welcomeText {\n    font-size: 25px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"greetingContainer": `greeting_greetingContainer__KMcqt`,
	"welcomeText": `greeting_welcomeText__7A6RD`,
	"nameText": `greeting_nameText__09Mvc`
};
export default ___CSS_LOADER_EXPORT___;
