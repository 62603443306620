// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewModalComponent_modalBody__GD2FK {
  border-radius: 20px !important;
  padding: 40px 40px 60px 40px !important;
}
.ViewModalComponent_header1__9xiS8 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.ViewModalComponent_downloadArrow__WxyEI {
  background-color: transparent;
  border: none;
}

@media (max-width: 426px) {
  .ViewModalComponent_modalBody__GD2FK {
    display: flex;
    /* width: 335px; */
    padding: 40px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    border-radius: 20px;
    background: #fff;
  }

  .ViewModalComponent_header1__9xiS8 {
    color: #212121;
    font-family: Desire-Pro;
    font-style: normal;
    margin-bottom: 1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    font-size: 28px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 12px !important;
  }

  .ViewModalComponent_mobileRow__RotKE {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/PurchaseComponent/ViewModalComponent/ViewModalComponent.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,uCAAuC;AACzC;AACA;EACE,cAAc;EACd,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,2CAA2C;AAC7C;AACA;EACE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE;IACE,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;IACT,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;IAClB,2CAA2C;;IAE3C,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,8BAA8B;EAChC;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".modalBody {\n  border-radius: 20px !important;\n  padding: 40px 40px 60px 40px !important;\n}\n.header1 {\n  color: #212121;\n  font-family: Desire-Pro;\n  font-size: 40px;\n  font-style: normal;\n  font-weight: 500;\n  margin-bottom: 1em;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.2);\n}\n.downloadArrow {\n  background-color: transparent;\n  border: none;\n}\n\n@media (max-width: 426px) {\n  .modalBody {\n    display: flex;\n    /* width: 335px; */\n    padding: 40px 20px;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 32px;\n    border-radius: 20px;\n    background: #fff;\n  }\n\n  .header1 {\n    color: #212121;\n    font-family: Desire-Pro;\n    font-style: normal;\n    margin-bottom: 1em;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.2);\n\n    font-size: 28px;\n    font-weight: 400;\n    line-height: 22px;\n    margin-bottom: 12px !important;\n  }\n\n  .mobileRow {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": `ViewModalComponent_modalBody__GD2FK`,
	"header1": `ViewModalComponent_header1__9xiS8`,
	"downloadArrow": `ViewModalComponent_downloadArrow__WxyEI`,
	"mobileRow": `ViewModalComponent_mobileRow__RotKE`
};
export default ___CSS_LOADER_EXPORT___;
