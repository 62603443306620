// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmModalComponent_header__ry\\+vC {
  font-family: Poppins;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  margin-bottom: 15px;
}
.confirmModalComponent_body__jmpBq {
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
}
.confirmModalComponent_OTPContainer__hAvb0 {
  justify-content: center;
}
.confirmModalComponent_InputComponent__Hyq-T {
  font-family: Poppins;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  color: #00112b;
  border-radius: 10px;
  border: solid 1px var(--secondary);
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
}
.confirmModalComponent_InputComponent__Hyq-T:focus {
  background-color: var(--secondary);
  color: #00112b;
}
.confirmModalComponent_resendButton__2feaj {
  color: var(--secondary);
  font-family: Poppins;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/MyProfileComponent/ProfileDetailCard/confirmModalComponent/confirmModalComponent.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,oBAAoB;EACpB,WAAW;EACX,YAAY;EACZ,cAAc;EACd,cAAc;EACd,mBAAmB;EACnB,kCAAkC;EAClC,wCAAwC;EACxC,kBAAkB;AACpB;AACA;EACE,kCAAkC;EAClC,cAAc;AAChB;AACA;EACE,uBAAuB;EACvB,oBAAoB;EACpB,gBAAgB;AAClB","sourcesContent":[".header {\n  font-family: Poppins;\n  font-weight: 600;\n  font-size: 25px;\n  text-align: center;\n  margin-bottom: 15px;\n}\n.body {\n  font-family: Poppins;\n  font-weight: 400;\n  font-size: 18px;\n  text-align: center;\n  margin-bottom: 10px;\n}\n.OTPContainer {\n  justify-content: center;\n}\n.InputComponent {\n  font-family: Poppins;\n  width: 60px;\n  height: 60px;\n  flex-shrink: 0;\n  color: #00112b;\n  border-radius: 10px;\n  border: solid 1px var(--secondary);\n  background-color: rgba(255, 255, 255, 0);\n  text-align: center;\n}\n.InputComponent:focus {\n  background-color: var(--secondary);\n  color: #00112b;\n}\n.resendButton {\n  color: var(--secondary);\n  font-family: Poppins;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `confirmModalComponent_header__ry+vC`,
	"body": `confirmModalComponent_body__jmpBq`,
	"OTPContainer": `confirmModalComponent_OTPContainer__hAvb0`,
	"InputComponent": `confirmModalComponent_InputComponent__Hyq-T`,
	"resendButton": `confirmModalComponent_resendButton__2feaj`
};
export default ___CSS_LOADER_EXPORT___;
