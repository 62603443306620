//@ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setKycStatus } from "../../../../redux/actions/user-action";
import { getKYCStatus } from "../../../../utils/common";
import { useDropzone } from 'react-dropzone'
import ButtonStyles from "../../../Common/Button.module.css";
import Modal from "react-bootstrap/Modal";
import Styles from "./additionalKYCDocModelComponent.module.css";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { FaFileUpload, FaTrash } from "react-icons/fa";
import notify from "../../../../utils/notify";
import Loader from "../../../Common/Loader";
import LoaderComponent from "../../../Common/LoaderComponent";
import { Spinner } from 'react-bootstrap';
import { submitKYCDoc } from "../../../../service/api";


type DocType = 'RESIDENCE_PERMIT'; // Add Sumsub doc types

const AdditionalKYCDocModalComponent = ({ hide }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop, multiple: false, noDrag: true, noClick: true, accept: {
      'application/pdf': []
    }
  })

  const onSubmit = async (event: Event) => {
    event.preventDefault();

    if (!file) {
      notify.warning('No document is selected!');
      return;
    }


    const formData = new FormData();
    formData.append('doc', file);
    formData.append('doc_filename', file.name);
    formData.append('idDocType', 'RESIDENCE_PERMIT' as DocType);

    try {
      setLoading(true);
      const response = await submitKYCDoc(formData);

      if (response.status) {
        dispatch(setKycStatus('Pending'));
        notify.success(response.message ?? 'Successfully uploaded the documents');
        setFile(null);
        hide();
      } else {
        notify.error(response.message ?? "Failed to upload the documents");
        hide();
      }
    } catch (error) {
      notify.error('Failed to upload the document');
      setFile(null)
    } finally {
      setLoading(false);
    }

  }

  return (
    <div className={Styles.body}>
      <Modal.Header>
        <Modal.Title className={Styles.header}>Upload Additional Documents</Modal.Title>
      </Modal.Header>

      <InputGroup className="my-3 w-75 mx-auto row ">
        <InputGroup.Text style={{ fontSize: '20px', fontWeight: 'bolder' }}>
          Residence Proof
        </InputGroup.Text>
        <div className="col d-flex p-0 mt-2 justify-content-between">
          {!file ? <Form.Control
            className="h-100 text-start"
            style={{ fontStyle: 'italic', fontSize: '14px' }}
            aria-label='Residence Proof'
            defaultValue='Upload proof of residence document'
            disabled
          /> : <iframe src={URL.createObjectURL(file)} title="Residence Proof" type="application/pdf" width="100%" height="600px"></iframe>}
          <InputGroup.Text className="ms-1" style={{ cursor: 'pointer' }} onClick={() => !file ? open() : setFile(null)} >
            {
              !file ?
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <FaFileUpload />
                </div> :
                <FaTrash className="text-danger" />
            }

          </InputGroup.Text>
        </div>
      </InputGroup>
      {isLoading && <>
        <div className={Styles.loader}>
          <Spinner animation="border" variant="warning" />
        </div>
      </>}
      <Modal.Footer>
        <button className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground}`} onClick={() => hide()}>Close</button>
        <button className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground}`} disabled={!file} onClick={onSubmit}>Submit</button>
      </Modal.Footer>

    </div>
  );
};
export default AdditionalKYCDocModalComponent;
