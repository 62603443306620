//@ts-nocheck
import { useDispatch, useSelector } from "react-redux";
import Styles from "./addMetamaskCard.module.css";
import { userDetailAPI, userUpdateAPI } from "../../../service/api";
import notify from "../../../utils/notify";
import walletImage from "../../../Assets/Images/goldefi/metaMaskWallet.svg";
import metaMaskFox from "../../../Assets/Images/goldefi/MetaMask_Fox.svg";
import { setUserDetails } from "../../../redux/actions";
import { copyText } from "../../../utils/common";
import { MdContentCopy } from "react-icons/md";
import { ConnectButton } from "thirdweb/react";
import { getClient, getWallets } from '../../../service/thirdweb_service';

const AddMetamaskCard = () => {
  const dispatch = useDispatch();
  const wallet_address = useSelector((state: any) => state.userReducer.userDetails.wallet_address ?? false);


  const trimAddress = (address: string, length = 5)=>{
      
    return `${address.substring(0, address.startsWith('0x') ? length + 2 : length)}...${address.substring(address.length - length)}`;
  }

  const addWalletToUser = async (address?: string) => {

    if (!address || wallet_address !== false || wallet_address.length > 0) return;

    //Update the user account with the selected wallet's account address
    const data = {
      wallet_address: address,
      wallet_provided_by: "user",
    };

    try {

      const updateUserResponse = await userUpdateAPI(data);

      if (!updateUserResponse.status) throw new Error('Wallet error');

      notify.success(updateUserResponse.message);
      const userData = await userDetailAPI();
      dispatch(setUserDetails(userData));

    } catch (error) {
      notify.error("Failed to connect the wallet");
    }

  }

  return (
    <>
      <div className={Styles.card2}>
        <div className="p-2">
          <img
            className={Styles.bannerImage}
            src={walletImage}
            alt="banner_image"
          />
        </div>
        <div className="container mt-4">
          <div className="row">
            <div className="col-3">
              <img
                className={Styles.bannerImage}
                src={metaMaskFox}
                alt="banner_image"
                width={48}
              />
            </div>
            <div className="col p-0">
              <h2 className={Styles.textHeading}>{wallet_address ? `Wallet Connected` : 'Connect Your Wallet'}</h2>
              <p className={Styles.text}>These are the professional details shown to users in the app.</p>
            </div>
          </div>
          <div className="row mt-4">
            {
              wallet_address && (
                <div className={`${Styles.text} font-wieght-normal h6 text-white`}>
                  <p className="d-flex px-1 justify-content-center text-break w-100">Address: {trimAddress(wallet_address,9)} <MdContentCopy size={18} style={{ minWidth: 20 }} className='cursor-pointer mx-1' onClick={() => copyText(wallet_address)} /></p>
                </div>
              )
            }

            {
              !wallet_address && (
                <div className="d-flex justify-content-center">
                  <ConnectButton client={getClient()}
                    wallets={getWallets()}
                    showAllWallets={false}
                    onConnect={async (wallet) => {
                      const address = wallet.getAccount()?.address;
                      await addWalletToUser(address);
                    }}
                  />
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMetamaskCard;
