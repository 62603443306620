//@ts-nocheck
import React, { useEffect, useState } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { fetchSumsubToken, userGetKYCAPI } from "../../../../service/api";
import { useDispatch, useSelector } from "react-redux";
import { setKycStatus } from "../../../../redux/actions/user-action";
import { getKYCStatus } from "../../../../utils/common";
const SumSubModalComponent = ({ hide }) => {
  const { userDetails } = useSelector((state) => state.userReducer);
  const [sumsubToken, setSumsubToken] = useState("");
  const dispatch = useDispatch();
  const fetchData = async () => {
    const resp = await fetchSumsubToken();
    if (resp.status) {
      setSumsubToken(resp.sumsub_access_token);
    }
  };

  const updateApplicationId = async (id: string) => {
    const body = {
      applicant_id: id,
    };
    const res = await userGetKYCAPI(body);
    if (res.status) {
      dispatch(setKycStatus(getKYCStatus(res.kyc_status, res.request_address_proof)));
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {sumsubToken && (
        <SumsubWebSdk
          accessToken={sumsubToken}
          onSubmitted={(response: any) => {
            console.log("Sumsub submitted:", response);
            userGetKYCAPI();
          }}
          config={{
            email: userDetails.email,
            phone: userDetails.mobile,
            theme: "light",
          }}
          onMessage={async (type, payload) => {
            if (type == "idCheck.onApplicantLoaded") {
              updateApplicationId(payload.applicantId);
            }
            if (type == "idCheck.onApplicantStatusChanged") {
              userGetKYCAPI();
            }
            if (
              type == "idCheck.onApplicantStatusChanged" &&
              payload.reviewStatus === "completed"
            ) {
              updateApplicationId("");
            } else if (
              type == "idCheck.onApplicantStatusChanged" &&
              payload.reviewStatus === "pending"
            ) {
              const timer = setTimeout(() => {
                hide();
              }, 5000);
              return () => {
                clearTimeout(timer);
              };
            }
          }}
          updateAccessToken={() => console.log("updateAccessToken")}
          expirationHandler={() => fetchData()}
        />
      )}
    </>
  );
};
export default SumSubModalComponent;
