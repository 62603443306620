// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buyMore_tokenContainer__jJ5Fc {
  min-height: 13em;
  border-radius: 10px;
  background: #132440;
  color :#fff;
}
.buyMore_tokenHeading__KBFjh{
  font-family: Poppins;
  font-size: 3em;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  word-wrap: break-word;

}
.buyMore_cardImg__qXYSB{ 
  object-fit: contain;
  object-position: left;
  height: 8em;
}


@media screen and (max-width: 767px){
  .buyMore_tokenHeading__KBFjh{       
      font-size: 1.5em;
      font-weight: 600;    
  }
}
@media screen  and (max-width: 480px) {
  .buyMore_tokenHeading__KBFjh{       
      font-size: 1.5em;
      font-weight: 600;    
  }
  .buyMore_cardImg__qXYSB {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/BuyMoreComponent/buyMore.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,oBAAoB;EACpB,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;;AAEvB;AACA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,WAAW;AACb;;;AAGA;EACE;MACI,gBAAgB;MAChB,gBAAgB;EACpB;AACF;AACA;EACE;MACI,gBAAgB;MAChB,gBAAgB;EACpB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".tokenContainer {\n  min-height: 13em;\n  border-radius: 10px;\n  background: #132440;\n  color :#fff;\n}\n.tokenHeading{\n  font-family: Poppins;\n  font-size: 3em;\n  font-weight: 600;\n  letter-spacing: 0em;\n  text-align: left;\n  word-wrap: break-word;\n\n}\n.cardImg{ \n  object-fit: contain;\n  object-position: left;\n  height: 8em;\n}\n\n\n@media screen and (max-width: 767px){\n  .tokenHeading{       \n      font-size: 1.5em;\n      font-weight: 600;    \n  }\n}\n@media screen  and (max-width: 480px) {\n  .tokenHeading{       \n      font-size: 1.5em;\n      font-weight: 600;    \n  }\n  .cardImg {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tokenContainer": `buyMore_tokenContainer__jJ5Fc`,
	"tokenHeading": `buyMore_tokenHeading__KBFjh`,
	"cardImg": `buyMore_cardImg__qXYSB`
};
export default ___CSS_LOADER_EXPORT___;
