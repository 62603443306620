import { FORGOT_PASSWORD } from "../redux-constant-type";

const setOTP = (otp: any) => {
  return {
    type: FORGOT_PASSWORD.SET_OTP,
    payload: {
      otp: otp,
    },
  };
};

const setOtpType = (otpType: any) => {
  return {
    type: FORGOT_PASSWORD.SET_OTP_TYPE,
    payload: {
      otpType: otpType,
    },
  };
};

export { setOTP, setOtpType };
