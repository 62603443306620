import Modal from "react-bootstrap/Modal";
import ButtonStyles from "../../../Common/Button.module.css";
import { IoWarningSharp } from "react-icons/io5";


const Notices = [
  'Go to the KYC section to upload the additional documents.',
  'We regret to inform you that due to international regulations or sanctions, GolDeFi does not support services in your country. Consequently, we must revoke your KYC (Know Your Customer) status on our platform. This revocation means you will no longer be able to purchase or redeem GDI directly from us.'
]

const AddressWarningModalComponent = ({
  notice = 0,
  show,
  onClose,
}: any) => {
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      centered={true}
      size="lg"
      show={show}
      onHide={onClose}>
      <Modal.Body>
        <p className="text-center my-3 regular-font">
          <div className="d-flex justify-content-center text-center mb-3">
            <IoWarningSharp size={40} color="#e8c056" />
            <div
              style={{ margin: "auto 0", fontWeight: 600, fontSize: "20px" }}>
              Notice on KYC Status
            </div>
          </div>
          {Notices[notice]}
        </p>

        <div className="d-flex justify-content-center gap-4 mt-4">
          <button
            type="submit"
            onClick={() => {
              onClose();
            }}
            className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground}`}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddressWarningModalComponent;
