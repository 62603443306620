// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_container__FGkwb {
  position: absolute;
  justify-self: center;
  /* max-width: 540px; */
  width: 540px;
  max-height: 307.8px;
  padding: 60px 40px;
  border-radius: 10px;
  background-color: rgba(149, 113, 54, 0.8);
  z-index: 999;
  display: flex;
  top: 30%;
  left: 30%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.loader_rotate__hlMNU {
  animation: loader_spin__CoNRX 2s linear infinite;
}
@keyframes loader_spin__CoNRX {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader_text1__-p710 {
  flex-grow: 0;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.loader_text2__58ogc {
  flex-grow: 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 426px) {
  .loader_container__FGkwb {
    width: 80% !important;
    left: 10%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Common/LoaderComponent/loader.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,yCAAyC;EACzC,YAAY;EACZ,aAAa;EACb,QAAQ;EACR,SAAS;EACT,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,gDAAkC;AACpC;AACA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;AACA;EACE,YAAY;EACZ,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,oBAAoB;EACpB,eAAe;EACf,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE;IACE,qBAAqB;IACrB,SAAS;EACX;AACF","sourcesContent":[".container {\n  position: absolute;\n  justify-self: center;\n  /* max-width: 540px; */\n  width: 540px;\n  max-height: 307.8px;\n  padding: 60px 40px;\n  border-radius: 10px;\n  background-color: rgba(149, 113, 54, 0.8);\n  z-index: 999;\n  display: flex;\n  top: 30%;\n  left: 30%;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 20px;\n}\n.rotate {\n  animation: spin 2s linear infinite;\n}\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.text1 {\n  flex-grow: 0;\n  font-family: Poppins;\n  font-size: 30px;\n  font-weight: 600;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  text-align: center;\n  color: #fff;\n}\n\n.text2 {\n  flex-grow: 0;\n  font-family: Poppins;\n  font-size: 16px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  text-align: center;\n  color: #fff;\n}\n\n@media screen and (max-width: 426px) {\n  .container {\n    width: 80% !important;\n    left: 10%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `loader_container__FGkwb`,
	"rotate": `loader_rotate__hlMNU`,
	"spin": `loader_spin__CoNRX`,
	"text1": `loader_text1__-p710`,
	"text2": `loader_text2__58ogc`
};
export default ___CSS_LOADER_EXPORT___;
