// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.additionalKYCDocModelComponent_header__fGM44 {
  font-family: Poppins;
}
.additionalKYCDocModelComponent_body__fyNIy {
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
}

.additionalKYCDocModelComponent_loader__hW\\+EV{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2)
}`, "",{"version":3,"sources":["webpack://./src/Components/MyProfileComponent/ProfileDetailCard/AdditionKYCDocModalComponent/additionalKYCDocModelComponent.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;AACA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ;AACF","sourcesContent":[".header {\n  font-family: Poppins;\n}\n.body {\n  font-family: Poppins;\n  font-weight: 400;\n  font-size: 18px;\n}\n\n.loader{\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0,0,0,0.2)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `additionalKYCDocModelComponent_header__fGM44`,
	"body": `additionalKYCDocModelComponent_body__fyNIy`,
	"loader": `additionalKYCDocModelComponent_loader__hW+EV`
};
export default ___CSS_LOADER_EXPORT___;
