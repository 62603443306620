// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacyPolicy_infoContainer__F5GCg {
  /* background-color: black;
  border-radius: 10px; */
  padding: 0em 3em 3em 3em;
  text-align: left;
}
.privacyPolicy_header1__Kocao {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -3px; */
  display: flex;
  justify-content: left;
  margin-bottom: 1em !important;
}
.privacyPolicy_diffColor__McY2o {
  color: #f4a537;
  font-family: Desire-Pro;
  margin-left: 10px;
}
.privacyPolicy_subheader__ecapW {
  color: #212121;
  font-family: Aileron;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 2em 0em;
}
.privacyPolicy_body__KS66H {
  margin: 2em 0em;
}
`, "",{"version":3,"sources":["webpack://./src/Components/PrivacyPolicyComponent/privacyPolicy.module.css"],"names":[],"mappings":"AAAA;EACE;wBACsB;EACtB,wBAAwB;EACxB,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,0BAA0B;EAC1B,aAAa;EACb,qBAAqB;EACrB,6BAA6B;AAC/B;AACA;EACE,cAAc;EACd,uBAAuB;EACvB,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,oBAAoB;EACpB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,eAAe;AACjB","sourcesContent":[".infoContainer {\n  /* background-color: black;\n  border-radius: 10px; */\n  padding: 0em 3em 3em 3em;\n  text-align: left;\n}\n.header1 {\n  color: #212121;\n  font-family: Desire-Pro;\n  font-size: 50px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 1em;\n  /* letter-spacing: -3px; */\n  display: flex;\n  justify-content: left;\n  margin-bottom: 1em !important;\n}\n.diffColor {\n  color: #f4a537;\n  font-family: Desire-Pro;\n  margin-left: 10px;\n}\n.subheader {\n  color: #212121;\n  font-family: Aileron;\n  font-size: 1.2em;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  margin: 2em 0em;\n}\n.body {\n  margin: 2em 0em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoContainer": `privacyPolicy_infoContainer__F5GCg`,
	"header1": `privacyPolicy_header1__Kocao`,
	"diffColor": `privacyPolicy_diffColor__McY2o`,
	"subheader": `privacyPolicy_subheader__ecapW`,
	"body": `privacyPolicy_body__KS66H`
};
export default ___CSS_LOADER_EXPORT___;
