//@ts-nocheck
import React from "react";
import Modal from "react-bootstrap/Modal";
import ButtonStyles from "../../../Common/Button.module.css";
import Styles from "./detailConfirmModalComponent.module.css";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';


const DetailConfirmModalComponent = ({
  handleCancel,
  handleConfirm,
  changes = []
}: any) => {

  return (
    <div className={Styles.body}>
      <Modal.Header>
        <Modal.Title className={Styles.header}>Confirm the following changes</Modal.Title>
      </Modal.Header>

      {
        changes.map((change, index) => change.value && (
          <InputGroup key={index} className="my-3 w-75 mx-auto">
            <InputGroup.Text style={{minWidth: "100px"}}>
              {change.field}
            </InputGroup.Text>
            <Form.Control
              className="h-100 text-start"
              aria-label={change.field}
              value={change.value}
              disabled
            />
          </InputGroup>
        ) )
      }

      <Modal.Footer>
        <button className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground}`} onClick={handleCancel}>Close</button>
        <button className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground}`} onClick={handleConfirm}>Confirm</button>
      </Modal.Footer>
    </div>
  );
};
export default DetailConfirmModalComponent;
