// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyProfile_page__rfNLT {
  background-color: #00112b;
  width: 100%;
  height: 100%;
  padding: 3em;
  overflow: auto;
}

.MyProfile_MyProfile__13YPD {
  flex-grow: 0;
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

@media screen and (max-width: 426px) {
  .MyProfile_page__rfNLT {
    height: unset;
    padding: 14px;
    display: grid;
    gap: 25px;
  }
  .MyProfile_MyProfile__13YPD {
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/MyProfileComponent/MyProfile.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE;IACE,aAAa;IACb,aAAa;IACb,aAAa;IACb,SAAS;EACX;EACA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".page {\n  background-color: #00112b;\n  width: 100%;\n  height: 100%;\n  padding: 3em;\n  overflow: auto;\n}\n\n.MyProfile {\n  flex-grow: 0;\n  font-family: Poppins;\n  font-size: 36px;\n  font-weight: 600;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.22;\n  letter-spacing: normal;\n  text-align: left;\n  color: #fff;\n}\n\n@media screen and (max-width: 426px) {\n  .page {\n    height: unset;\n    padding: 14px;\n    display: grid;\n    gap: 25px;\n  }\n  .MyProfile {\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `MyProfile_page__rfNLT`,
	"MyProfile": `MyProfile_MyProfile__13YPD`
};
export default ___CSS_LOADER_EXPORT___;
