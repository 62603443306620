import React from "react";
import Styles from "./SelectField.module.css";
import { SelectInterface } from "../../../utils/interfaces/inputInterface";
import Form from "react-bootstrap/Form";

const CountrySelectField = ({
  // label,
  // important = false,
  value,
  options = [],
  onChange,
  placeholder = "",
  disabled,
  name,
  setState,
  dark = false,
  defaultValue
}: SelectInterface & {
  setState: (states: Array<Object>) => void;
  defaultValue?: string
  dark: boolean;
}) => {
  return (
    <Form.Select
      className={
        Styles.selectBox +
        " " +
        (dark ? Styles.darkInput : "") +
        " " +
        (value ? Styles.highlight : "")
      }
      aria-label={placeholder}
      onChange={(e) => {
        onChange(e);
      }}
      name={name}
      disabled={disabled}
      value={defaultValue ? undefined : value}
      defaultValue={defaultValue}
      >
      <option>{placeholder}</option>
      {options.length &&
        options.sort((x: any, y: any)=>new Intl.Collator('en', { sensitivity: 'base'}).compare(x.country_name, y.country_name)).map((option: any) => {
          return (
            <option key={option.country_id} value={option.country_name}>
              {option.country_name}
            </option>
          );
        })}
    </Form.Select>
  );
};
const convertString = (str: string, name?: string) => {
  // Split the string by underscores
  let capitalized = "";
  const parts = str.split("_");
  capitalized = parts
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(" ");
  if (name === "annualRevenue") {
    // Capitalize the first letter of each part and join them with spaces
    capitalized = capitalized.replace("sd", "SD");
  }

  return capitalized;
};
const StateSelectField = ({
  // label,
  // important = false,
  value,
  options = [],
  onChange,
  placeholder = "",
  disabled,
  name,
  dark = false,
  defaultValue
}: SelectInterface) => {
  return (
    <Form.Select
      className={
        Styles.selectBox +
        " " +
        (dark ? Styles.darkInput : "") +
        " " +
        (value ? Styles.highlight : "")
      }
      aria-label={placeholder}
      onChange={(e) => {
        onChange(e);
      }}
      name={name}
      disabled={disabled}
      value={defaultValue ? undefined : value}
      defaultValue={defaultValue}
      >
      <option>{placeholder}</option>
      {options.length &&
        options.map((option: any) => (
          <option key={option.id} value={option.name}>
            {option.name}
          </option>
        ))}
    </Form.Select>
  );
};

const SelectField = ({
  // label,
  // important = false,
  value,
  options = [],
  onChange,
  placeholder = "",
  disabled,
  name,
  dark = false,
}: SelectInterface & {
  setState: (states: Array<Object>) => void;
  dark: boolean;
}) => {
  return (
    <Form.Select
      className={
        Styles.selectBox +
        " " +
        (dark ? Styles.darkInput : "") +
        " " +
        (value ? Styles.highlight : "")
      }
      aria-label={placeholder}
      onChange={(e) => {
        onChange(e);
      }}
      name={name}
      disabled={disabled}
      value={value}>
      <option disabled>{placeholder}</option>
      {options.length &&
        options.map((option: any) => (
          <option key={option} value={option}>
            {/* {convertString(option, name)} */}
            {option}
          </option>
        ))}
    </Form.Select>
  );
};

export default SelectField;

export { CountrySelectField, StateSelectField, SelectField };
