// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../../index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserHeader_selectedIcon__KJWbF {
  color: var(--secondary);
  margin-left: 10px;
  font-family: Poppins;
  font-weight: 500;
}
.UserHeader_icon__1UVEK {
  margin-left: 10px;
  font-family: Poppins;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Common/UserHeader/icons/UserHeader.module.css"],"names":[],"mappings":"AAEA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,oBAAoB;EACpB,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,gBAAgB;AAClB","sourcesContent":["@import url(\"../../../../index.css\");\n\n.selectedIcon {\n  color: var(--secondary);\n  margin-left: 10px;\n  font-family: Poppins;\n  font-weight: 500;\n}\n.icon {\n  margin-left: 10px;\n  font-family: Poppins;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedIcon": `UserHeader_selectedIcon__KJWbF`,
	"icon": `UserHeader_icon__1UVEK`
};
export default ___CSS_LOADER_EXPORT___;
