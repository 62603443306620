// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubmitModelComponent_header__xlPD- {
  flex-grow: 0;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #00112b;
}
.SubmitModelComponent_subHeader__PZk7M {
  flex-grow: 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #00112b;
}
.SubmitModelComponent_body__SXCIw {
  color: #212121;
  font-family: Aileron;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 2rem auto;

  gap: 25px;
  max-width: 350px;
  display: grid;
  /* display: flex; */
}
.SubmitModelComponent_btn__Fuf1o {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/SearchOrdersComponent/SubmitModelComponent/SubmitModelComponent.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,YAAY;EACZ,oBAAoB;EACpB,eAAe;EACf,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,cAAc;EACd,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;;EAEjB,SAAS;EACT,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".header {\n  flex-grow: 0;\n  font-family: Poppins;\n  font-size: 24px;\n  font-weight: 600;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  text-align: center;\n  color: #00112b;\n}\n.subHeader {\n  flex-grow: 0;\n  font-family: Poppins;\n  font-size: 14px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.71;\n  letter-spacing: normal;\n  text-align: center;\n  color: #00112b;\n}\n.body {\n  color: #212121;\n  font-family: Aileron;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  margin: 2rem auto;\n\n  gap: 25px;\n  max-width: 350px;\n  display: grid;\n  /* display: flex; */\n}\n.btn {\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `SubmitModelComponent_header__xlPD-`,
	"subHeader": `SubmitModelComponent_subHeader__PZk7M`,
	"body": `SubmitModelComponent_body__SXCIw`,
	"btn": `SubmitModelComponent_btn__Fuf1o`
};
export default ___CSS_LOADER_EXPORT___;
