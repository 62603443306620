// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectField_selectBox__ZnRC3 {
  /* width: 57px; */
  /* height: 21px; */
  flex-grow: 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #646464;

  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  padding: 20px !important;
  border-radius: 5px !important;
  border: solid 1px #c2c2c2 !important;
  background-color: #fff !important;
}

.SelectField_darkInput__UiYoF {
  background-position: right 2rem center !important;
  background-color: #0a1a33 !important;
  color: #646464 !important;
  border: 0px !important;
  font-size: 18px;
}

.SelectField_highlight__U0aTT {
  border: solid 1px #e8c056 !important;
  color: #e8c056 !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/SelectField/SelectField.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;EAChB,cAAc;;EAEd,wBAAwB;EACxB,8BAA8B;EAC9B,sCAAsC;EACtC,kCAAkC;EAClC,wBAAwB;EACxB,6BAA6B;EAC7B,oCAAoC;EACpC,iCAAiC;AACnC;;AAEA;EACE,iDAAiD;EACjD,oCAAoC;EACpC,yBAAyB;EACzB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B","sourcesContent":[".selectBox {\n  /* width: 57px; */\n  /* height: 21px; */\n  flex-grow: 0;\n  font-family: Poppins;\n  font-size: 14px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  text-align: left;\n  color: #646464;\n\n  display: flex !important;\n  flex-direction: row !important;\n  justify-content: flex-start !important;\n  align-items: flex-start !important;\n  padding: 20px !important;\n  border-radius: 5px !important;\n  border: solid 1px #c2c2c2 !important;\n  background-color: #fff !important;\n}\n\n.darkInput {\n  background-position: right 2rem center !important;\n  background-color: #0a1a33 !important;\n  color: #646464 !important;\n  border: 0px !important;\n  font-size: 18px;\n}\n\n.highlight {\n  border: solid 1px #e8c056 !important;\n  color: #e8c056 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectBox": `SelectField_selectBox__ZnRC3`,
	"darkInput": `SelectField_darkInput__UiYoF`,
	"highlight": `SelectField_highlight__U0aTT`
};
export default ___CSS_LOADER_EXPORT___;
