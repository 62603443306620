// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detailConfirmModalComponent_header__GM8bn {
  font-family: Poppins;
}
.detailConfirmModalComponent_body__bE6u\\+ {
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/Components/MyProfileComponent/ProfileDetailCard/detailConfirmModalComponent/detailConfirmModalComponent.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;AACA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".header {\n  font-family: Poppins;\n}\n.body {\n  font-family: Poppins;\n  font-weight: 400;\n  font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `detailConfirmModalComponent_header__GM8bn`,
	"body": `detailConfirmModalComponent_body__bE6u+`
};
export default ___CSS_LOADER_EXPORT___;
