// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_DesktopFooterContainer__q5usl {
  display: flex;
  background: var(--primary);
  text-align: left;
}
.Footer_topContainer__bg4sI {
  border-top: 2px solid var(--secondary);
  margin-top: 5vw;
  padding-top: 5vw;
  margin-bottom: 5vw;
}
.Footer_header__7bMvS {
  font-size: 0.9vw;
  opacity: 70%;
}
.Footer_links__xkI8T {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  color: var(--white);
  font-family: Hellix;
}
.Footer_links__xkI8T img {
  width: 15vw;
  margin-top: -2.5vw;
}
.Footer_links__xkI8T div {
  font-size: 1.2vw;
}
.Footer_links__xkI8T a {
  color: var(--white) !important;
}
.Footer_imageContainer__6tH2a {
  display: flex;
  flex-direction: row;
  gap: 1vw;
}
.Footer_imageContainer__6tH2a img {
  padding-top: 1vw;
  width: 2vw;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Common/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,0BAA0B;EAC1B,gBAAgB;AAClB;AACA;EACE,sCAAsC;EACtC,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;AACA;EACE,gBAAgB;EAChB,UAAU;EACV,eAAe;AACjB","sourcesContent":[".DesktopFooterContainer {\n  display: flex;\n  background: var(--primary);\n  text-align: left;\n}\n.topContainer {\n  border-top: 2px solid var(--secondary);\n  margin-top: 5vw;\n  padding-top: 5vw;\n  margin-bottom: 5vw;\n}\n.header {\n  font-size: 0.9vw;\n  opacity: 70%;\n}\n.links {\n  display: flex;\n  flex-direction: column;\n  gap: 1vw;\n  color: var(--white);\n  font-family: Hellix;\n}\n.links img {\n  width: 15vw;\n  margin-top: -2.5vw;\n}\n.links div {\n  font-size: 1.2vw;\n}\n.links a {\n  color: var(--white) !important;\n}\n.imageContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 1vw;\n}\n.imageContainer img {\n  padding-top: 1vw;\n  width: 2vw;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DesktopFooterContainer": `Footer_DesktopFooterContainer__q5usl`,
	"topContainer": `Footer_topContainer__bg4sI`,
	"header": `Footer_header__7bMvS`,
	"links": `Footer_links__xkI8T`,
	"imageContainer": `Footer_imageContainer__6tH2a`
};
export default ___CSS_LOADER_EXPORT___;
