// @ts-nocheck

import React, { useState, useEffect, useRef } from "react";
import Styles from "./profileDetailCard.module.css";
import InputField from "../../Common/InputField";
import Switch from "../../Common/SwitchInput";
import notify from "../../../utils/notify";
import {
  getAccessToken,
  getUserId,
  getAWSToken,
  getUserEmail,
  clearLocalStorage,
  isObjectEmpty,
  getKYCStatus,
} from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import DefaultLogo from "../../../Assets/Images/goldefi/user.svg";
import UnionCard from "../../../Assets/Images/goldefi/Union.svg";
import KYCDone from "../../../Assets/Images/goldefi/kycDone.svg";
import SelectField, {
  CountrySelectField,
  StateSelectField,
} from "../../Common/SelectField";
import ButtonStyle from "../../Common/Button.module.css";
import {
  disable2Fa,
  fetchProfessionalDetail,
  updateEmail,
  userDetailAPI,
  userGetKYCAPI,
  userUpdateAPI,
} from "../../../service/api";
import { setUserDetails } from "../../../redux/actions";
import { setKycReason, setKycStatus, setUserLogout } from "../../../redux/actions/user-action";
import ChangePasswordComponent from "../ChangePasswordComponent";
import Modal from "react-bootstrap/Modal";
import MFAModelComponent from "./2FAModelComponent";
import SumSubModalComponent from "./sumsubModalComponent";
import AdditionalKYCDocModalComponent from "./AdditionKYCDocModalComponent";
import ConfirmModalComponent from "./confirmModalComponent";
import { adminDetailsAPI } from "../../../service/api";
import SelectComponent from "../../BuyToken/SelectComponent";
import { useNavigate } from "react-router-dom";
import AddressWarningModalComponent from "./addressWarningModalComponent";
import moment from "moment";
import DetailConfirmModalComponent from "./detailConfirmModalComponent";

const useFocus = () => {
  const ref = useRef(null);
  const setFocus = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  return { setFocus, ref };
};
const ProfileDetailCard = ({ role, isLoading, setLoader, setLoaderMessage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const input1Focus = useFocus();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [activeTab, setActiveTab] = useState("Ess_info");
  const [editAddress, setEditAddress] = useState(true);
  const [editEssInfo, setEditEssInfo] = useState(true);
  const [showCountryChangeNotice, setCountryChangeNotice] = useState(0);
  const [isOn, setIsOn] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const [fileview2, setfileView2] = useState();
  const { userDetails, kycStatus } = useSelector(
    (state: any) => state.userReducer
  );
  const [fullAddress, setFullAddress] = useState({
    address: "",
    address2: "",
    country: "",
    zip: "",
    state: "",
    city: "",
  });
  const [essInfo, setEssInfo] = useState({
    name: "",
    lastName: "",
    email: "",
    mobile: "",
    dob: "",
    professional_act: "",
    sector_of_act: "",
    range_of_annual_rev: "",
  });
  const [secondPageValues, setSecondPageValue] = useState({
    professionalActivityList: ["investor"],
    sectorActivityList: ["banking"],
    annualRevenueList: ["below_10000_usd"],
  });

  const [isOpen, setIsOpen] = useState(false);
  const [kycModal, setKycModal] = useState(false);
  const [showdetailConfirmation, setShowdetailConfirmation] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [blackListCountry, setBlackListCountry] = useState();
  const [greyListCountry, setGreyListCountry] = useState();
  const [confirmedData, setConfirmedData] = useState({});

  const sendOTP = async (email: string) => {
    const res = await updateEmail({ email });
    if (res.status) {
      notify.success(res.message);
      return true;
    } else {
      hideModal(setConfirmModal);
      return false;
    }
  };

  const onAddressChangeConfirm = async () => {
    const country = fullAddress.country;
    const oldCountry = userDetails.country;


    if (isBlacklisted(country)) {
      dispatch(setKycStatus('Not_Eligible'))
      dispatch(setKycReason(""));
      await handleUpdate('fullAddress', fullAddress);
      setCountryChangeNotice(1)
      setAddressModal(true);
      return
    }

    if (isGreyListed(country)) {
      await handleUpdate('fullAddress', fullAddress);
      setCountryChangeNotice(0)
      setAddressModal(true);
      return;
    }

    await handleUpdate('fullAddress', fullAddress);

    if (isGreyListed(oldCountry)) {
      setAddressModal(true);
      setCountryChangeNotice(0)
    } else {
      setAddressModal(false);
    }

  }

  const onAddressChangeCancel = () => {
    setAddressModal(false);
  }

  const onFileChange = async (e: any) => {
    const img = e.target.files[0];
    const MAX_SIZE_MB = 9;
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;
    if (img?.type.includes("image") && !img?.type.includes("webp")) {
      if (img.size > MAX_SIZE_BYTES) {
        notify.error(
          `File size exceeds ${MAX_SIZE_MB}MB. Please upload a smaller file.`
        );
        return;
      }
      const userId = getUserId();
      const accessToken = getAccessToken();
      const reader: any = new FileReader();
      reader.addEventListener("load", () => {
        setfileView2(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
      const formData = new FormData();
      formData.append("image", e.target.files[0]);

      formData.append("user_id", userId);

      formData.append("access_token", accessToken);

      await fetch(apiUrl + "image/upload", {
        method: "POST",
        headers: {
          // "Content-Type": "multipart/form-data",
          auth_key: "1BAQ6QEY0Qb0ITzG4A",
        },
        body: formData,
      })
        .then((response) => response.json())
        .then(async (data) => {
          if (data.status) {
            notify.success(data.message);
            window.location.reload();
          } else {
            notify.error(data.message || "Something went wrong");
          }
        });
    }
  };
  const getProfessionalDetail = async () => {
    const res: any = await fetchProfessionalDetail();
    if (res.status) {
      setSecondPageValue({
        professionalActivityList: res.professional_activity,
        sectorActivityList: res.sector_of_activity,
        annualRevenueList: res.range_of_annual_revenues,
      });
    }
  };
  const handleImageClick = () => {
    hiddenFileInput.current.click();
  };
  const update = async (data) => {
    setLoader(true);
    setLoaderMessage('Updating the user details');
    const res: any = await userUpdateAPI(data);
    if (res.status) {
      notify.success(res.message);
      const resp = await userDetailAPI();
      const kycRes: any = await userGetKYCAPI();
      dispatch(setKycStatus(getKYCStatus(kycRes.kyc_status, kycRes.request_address_proof)));
      dispatch(setKycReason(kycRes.reason ? kycRes.reason : ""));
      dispatch(setUserDetails(resp));
      // reset values
      setFullAddress({
        address: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zip: '',
      })
    } else {
      notify.error(res?.message ?? 'Failed update the details');
    }
    setLoader(false);
  };
  const handleUpdate = async (label: string, value: string) => {
    let data = {};
    if (label === "fullAddress") {
      data = {
        street: fullAddress.address,
        street2: fullAddress.address2,
        country: fullAddress.country,
        zip: fullAddress.zip,
        state: fullAddress.state,
        city: fullAddress.city,
      };
    } else if (label === "essInfo") {
      const regexName = /^[^*|\":<>[\]{}`\\()'!#%^_+,./~?;@&$]+$/;
      const regexMobile = /^\+?[0-9]{8,15}$/;
      const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (!essInfo.name.trim()) {
        notify.error("Name is required.");
        setEssInfo({ ...essInfo, name: userDetails.name });
        return;
      } else if (!regexName.test(essInfo.name.trim())) {
        notify.error("Invalid characters in the name.");
        setEssInfo({ ...essInfo, name: userDetails.name });
        return;
      }
      if (!regexMobile.test(essInfo.mobile.trim())) {
        notify.error("Invalid Phone Number.");
        setEssInfo({ ...essInfo, mobile: userDetails.mobile });
        return;
      }
      if (!essInfo.email.trim()) {
        // errors.email = "Email is required.";
        notify.error("Email is required.");
        return;
      } else if (!regexEmail.test(essInfo.email.trim())) {
        // errors.email = "Invalid email format.";
        notify.error("Invalid email format.");
        return;
      }

      Object.keys(essInfo).forEach((key) => {
        if (essInfo[key] !== userDetails[key]) {
          data = {
            ...data,
            [key]: essInfo[key],
          };
        }
      });

      if (data.email) {
        setLoader(true);
        setLoaderMessage('OTP Sending to the new email added');
        const res = await sendOTP(data.email);
        setLoader(false);
        if (res) {
          setConfirmModal(true);
          setConfirmedData(data);
        }
        return;
      }
    } else {
      if (value.trim() === "") {
        notify.error("Fields Cannot be Empty");
        return;
      }
      const REGEX_ADDRESS = /^0x[a-fA-F0-9]{40}$/;
      if (label === "Wallet Address" && !REGEX_ADDRESS.test(value)) {
        notify.error("Enter Valid Wallet Address");
        return;
      }
      const key =
        label == "Name"
          ? "name"
          : label == "Email"
            ? "email"
            : label == "Phone"
              ? "mobile"
              : label == "Country (optional)"
                ? "country"
                : label == "Wallet Address"
                  ? "wallet_address"
                  : "";
      data = {
        [key]: value,
      };
    }
    await update(data);
  };
  const showModal = () => {
    if (!userDetails.two_factor_auth) {
      setIsOpen(true);
    } else {
      notify.error("ERROR", "Error", "Google Authenticator already enabled.");
    }
  };
  const disableMFA = async () => {
    if (userDetails.two_factor_auth) {
      const userId = getUserId();
      const awsToken = getAWSToken();
      const userName = getUserEmail();
      const request = {
        user_id: userId,
        user_name: userName,
        aws_sso_token: awsToken,
      };
      const res = await disable2Fa(request);
      if (res.status) {
        const res = await userDetailAPI();
        dispatch(setUserDetails(res));
        notify.success("SUCCESS", "Success", res.message);
        setIsOn(false);
      } else {
        notify.error("ERROR", "Error", res.message);
      }
    } else {
      notify.error("ERROR", "Error", "Please enable 2FA first.");
    }
  };
  const hideModal = (setState) => {
    setState(false);
  };
  const getCountryList = async () => {
    const res: any = await adminDetailsAPI();
    if (res.status) {
      const data = res.admin_details.countries_details;
      const blackList = res.black_listed_countries;
      const greyList = res.grey_listed_countries;
      setBlackListCountry(blackList);
      setGreyListCountry(greyList);
      setCountryList(data);
      if (userDetails.country) {
        const country = data.find(
          (item) => item.country_name === userDetails.country
        );
        setStateList(country.states);
      }
    } else {
      setCountryList([]);
    }
  };

  const loadState = () => {
    const currentCountry = userDetails.country;

    if (!currentCountry) return;

    setStateList((countryList.find((value) => value.country_name === currentCountry).states ?? []));
  }

  useEffect(() => {
    getCountryList()
  }, [])
  useEffect(() => {
    // setFullAddress({
    //   address: userDetails.address,
    //   address2: userDetails.address2,
    //   country: userDetails.country,
    //   zip: userDetails.zip,
    //   state: userDetails.state,
    //   city: userDetails.city,
    // });
    setEssInfo({
      name: userDetails.name,
      lastName: userDetails.last_name,
      email: userDetails.email,
      mobile: userDetails.mobile,
      dob: userDetails.date_of_birth,
      professional_act: userDetails.professional_act,
      sector_of_act: userDetails.sector_of_act,
      range_of_annual_rev: userDetails.range_of_annual_rev,
    });
    setIsOn(userDetails.two_factor_auth);
  }, [userDetails]);
  const isBlacklisted = (countryName) => {
    return blackListCountry.some(
      (country) => country.country_name === countryName
    );
  };
  const isGreyListed = (countryName) => {
    return greyListCountry.some(
      (country) => country.country_name === countryName
    );
  };
  const tabsList = [
    {
      label: "Ess info",
      value: "Ess_info",
    },
    {
      label: "KYC status",
      value: "KYC_status",
    },
    {
      label: "Physical Address",
      value: "Physical_Address",
    },
    {
      label: "Update Password",
      value: "Update_Password",
    },
    {
      label: "Google Authenticator",
      value: "Google_Authenticator",
    },
  ];

  const changeStates = (country) => {
    const sta = countryList.find((countryObj) => {
      return countryObj.country_name === country;
    });
    setStateList(sta.states);
  };
  const startKyc = () => {
    if (userDetails.wallet_address) {
      setKycModal(true);
    } else {
      notify.error("Please link your Wallet with the Platform.");
    }
  };
  return (
    <>
      <div className={Styles.card1}>
        <div className="grid row gap-4">
          <div className={Styles.tabs + " col-12 col-sm-3"}>
            <div className={Styles.profileImg + " mb-4"}>
              <img
                src={
                  fileview2
                    ? fileview2
                    : userDetails.user_image_url
                      ? userDetails.user_image_url
                      : DefaultLogo
                }
                alt="logo"
                className={`${Styles.defaultLogo} ${fileview2 || userDetails.user_image_url ? "rounded" : ""
                  }`}
                onClick={handleImageClick}
              />
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={onFileChange}
                hidden
                accept="image/png, image/jpeg"
              />
            </div>
            {/* Desktop View */}
            <div
              className={Styles.tabsList}
              onClick={(e) => {
                if (!isLoading)
                  setActiveTab(e.target.id)

                // reset values
                setFullAddress({
                  address: '',
                  address2: '',
                  city: '',
                  state: '',
                  country: '',
                  zip: '',
                });
                setEditAddress(true)
              }}>
              <button
                id="Ess_info"
                className={
                  Styles.tabText +
                  " " +
                  (activeTab === "Ess_info" && Styles.focusText)
                }>
                Essential Information
              </button>
              <button
                id="KYC_status"
                className={
                  Styles.tabText +
                  " " +
                  (activeTab === "KYC_status" && Styles.focusText)
                }>
                KYC Status
                {/* <img
                  src={kycCompleted ? KYCDoneIcon : KYCNotDoneIcon}
                  style={{ marginLeft: "20px" }}
                  alt="KYC"
                /> */}
              </button>
              <button
                id="Physical_Address"
                className={
                  Styles.tabText +
                  " " +
                  (activeTab === "Physical_Address" && Styles.focusText)
                }>
                Physical Address
              </button>
              <button
                id="Update_Password"
                className={
                  Styles.tabText +
                  " " +
                  (activeTab === "Update_Password" && Styles.focusText)
                }>
                Update Password
              </button>
              <button
                id="Google_Authenticator"
                className={
                  Styles.tabText +
                  " " +
                  (activeTab === "Google_Authenticator" && Styles.focusText)
                }>
                Google Authenticator
              </button>
            </div>
          </div>
          {/* Mobile View */}
          <div className={Styles.tabListMobile}>
            <SelectComponent
              disabled={isLoading}
              onChange={setActiveTab}
              list={tabsList}
            />
          </div>
          <div className={Styles.Line + " col-1"}></div>
          <div className=" col-12 col-sm-8 col-lg-7 col-xl-8">
            {activeTab === "Ess_info" && (
              <div className={role !== "user" ? "d-flex" : ""}>
                <div className={Styles.detailsCol}>
                  <div className={Styles.textHeading}>
                    Essential Information
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-12 col-xl-6 py-2">
                      {/* <InputField
                        label="First Name"
                        value={
                          editEssInfo ? userDetails.name || "N/A" : essInfo.name
                        }
                        type="text"
                        placeholder="Name"
                        name="name"
                        disabled={editEssInfo}
                        onChange={(e) => {
                          const isValid = /^[A-Za-z_\- ]+$/.test(
                            e.target.value
                          );
                          if (isValid || e.target.value === "") {
                            setEssInfo({ ...essInfo, name: e.target.value });
                          }
                        }}
                        maxLength={30}
                      /> */}
                      <div
                        className={`${Styles.highlight}   ${Styles.inputContainer}`}>
                        <div className={Styles.inputFieldContainer}>
                          <div className={Styles.labelContainer}>
                            <p className={Styles.label}>First Name</p>
                          </div>

                          <input
                            className={Styles.inputBox}
                            onChange={(e) => {
                              const isValid = /^[A-Za-z_\- ]+$/.test(
                                e.target.value
                              );
                              if (isValid || e.target.value === "") {
                                setEssInfo({
                                  ...essInfo,
                                  name: e.target.value,
                                });
                              }
                            }}
                            value={
                              editEssInfo
                                ? userDetails.name || "N/A"
                                : essInfo.name
                            }
                            disabled={editEssInfo}
                            type="text"
                            placeholder="Name"
                            name="name"
                            maxLength={30}
                            ref={input1Focus.ref}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-12 col-xl-6 py-2">
                      <InputField
                        label="Last Name"
                        value={
                          editEssInfo
                            ? userDetails.last_name || "N/A"
                            : essInfo.lastName
                        }
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        disabled={editEssInfo}
                        onChange={(e) => {
                          const isValid = /^[A-Za-z_\- ]+$/.test(
                            e.target.value
                          );
                          if (isValid || e.target.value === "") {
                            setEssInfo({
                              ...essInfo,
                              lastName: e.target.value,
                            });
                          }
                        }}
                        maxLength={30}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-12 col-xl-6 py-2">
                      <InputField
                        label="Email Address"
                        value={
                          editEssInfo
                            ? userDetails.email || "N/A"
                            : essInfo.email
                        }
                        type="text"
                        placeholder="Email"
                        name="email"
                        disabled={editEssInfo}
                        onChange={(e) =>
                          setEssInfo({ ...essInfo, email: e.target.value })
                        }
                        maxLength={30}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-12 col-xl-6 py-2">
                      <InputField
                        label="Phone"
                        value={
                          editEssInfo
                            ? userDetails.mobile || "N/A"
                            : essInfo.mobile
                        }
                        type="text"
                        placeholder="Phone"
                        name="phone"
                        disabled={editEssInfo}
                        onChange={(e) =>
                          setEssInfo({ ...essInfo, mobile: e.target.value })
                        }
                        maxLength={15}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-12 col-xl-6 py-2">
                      <InputField
                        label="Date Of Birth"
                        value={
                          moment(
                            userDetails.date_of_birth,
                            "DD-MM-YYYY"
                          ).format("YYYY-MM-DD") || "N/A"
                        }
                        type="date"
                        placeholder="Date Of Birth"
                        onChange={(e) =>
                          setEssInfo({ ...essInfo, dob: e.target.value })
                        }
                        name="dob"
                        disabled={true}
                      />
                    </div>
                    {role === "user" && (
                      <>
                        <div className="col-12 col-md-6 col-lg-12 col-xl-6 py-2">
                          {editEssInfo ? (
                            <InputField
                              label="Professional Activity"
                              value={userDetails.professional_act || "N/A"}
                              type="text"
                              placeholder="Professional Activity"
                              name="professional_act"
                              disabled={editEssInfo}
                            />
                          ) : (
                            <SelectField
                              value={essInfo.professional_act}
                              options={
                                secondPageValues.professionalActivityList
                              }
                              type="text"
                              placeholder="Professional Activity"
                              name="professional_act"
                              disabled={false}
                              onChange={(e) => {
                                if (e !== "Professional Activity")
                                  setEssInfo({
                                    ...essInfo,
                                    professional_act: e.target.value,
                                  });
                              }}
                            />
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-12 col-xl-6 py-2">
                          {editEssInfo ? (
                            <InputField
                              label="Range Of Annual Revenue"
                              value={userDetails.range_of_annual_rev || "N/A"}
                              type="text"
                              placeholder="Range Of Annual Revenue"
                              name="Range Of Annual Revenue"
                              disabled={editEssInfo}
                            />
                          ) : (
                            <SelectField
                              value={essInfo.range_of_annual_rev}
                              options={secondPageValues.annualRevenueList}
                              placeholder="Range Of Annual Revenue"
                              name="annualRevenueList"
                              disabled={false}
                              onChange={(e) => {
                                if (e !== "Range Of Annual Revenue")
                                  setEssInfo({
                                    ...essInfo,
                                    range_of_annual_rev: e.target.value,
                                  });
                              }}
                            />
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-12 col-xl-6 py-2">
                          {editEssInfo ? (
                            <InputField
                              label="Sector Of Activity"
                              value={userDetails.sector_of_act || "N/A"}
                              type="text"
                              placeholder="Sector Of Activity"
                              name="sector_of_act"
                              disabled={editEssInfo}
                            />
                          ) : (
                            <SelectField
                              value={essInfo.sector_of_act}
                              options={secondPageValues.sectorActivityList}
                              placeholder="Sector Of Activity"
                              name="sectorActivityList"
                              disabled={false}
                              onChange={(e) => {
                                if (e !== "Sector Of Activity")
                                  setEssInfo({
                                    ...essInfo,
                                    sector_of_act: e.target.value,
                                  });
                              }}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <button
                    // onClick={() => handleUpdate(editField, parentValue)}
                    onClick={() => {
                      if (editEssInfo) {
                        setEditEssInfo(false);
                        getProfessionalDetail();
                        setTimeout(() => {
                          input1Focus.setFocus();
                        }, 500);
                      } else {
                        setEditEssInfo(true);
                        handleUpdate("essInfo", essInfo);
                      }
                    }}
                    className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} ${Styles.updateBtn} px-2 my-4`}>
                    {!editEssInfo ? "Update" : "Edit"}
                  </button>
                </div>
                {/* {role !== "user" && (
                  <img className={Styles.mobDNone} src={Profile} alt="banner" />
                )} */}
              </div>
            )}
            {activeTab === "KYC_status" && (
              <div className={Styles.kycContent}>
                <div className={Styles.textHeading}>KYC Status</div>
                <img
                  src={kycStatus === "Verified" ? KYCDone : UnionCard}
                  alt="logo"
                  style={{ margin: "auto" }}
                  className={Styles.kycImg}
                />
                <div className={Styles.kycHeading}>
                  {kycStatus === "Verified"
                    ? "KYC Completed"
                    : kycStatus === "Rejected"
                      ? "KYC Rejected"
                      : kycStatus === "Pending"
                        ? "Your KYC is In-Review"
                        : kycStatus === 'Not_Eligible' ?
                          'Not Eligible For KYC'
                          : kycStatus === 'Require_Docs' ?
                            'Additional Documents Are Required'
                            : "Complete your KYC"}
                </div>
                <div className={Styles.kycText}>
                  {kycStatus === "Verified" ? (
                    `We are delighted to inform you that your KYC process has been successfully completed.`
                  ) : kycStatus === "Rejected" ? (
                    <>
                      We are like to inform you that your KYC process has been
                      Rejected.
                      <br />
                      <br />
                      {userDetails.kyc_reason ? userDetails.kyc_reason : ""}
                    </>
                  ) : kycStatus === "Pending" ? (
                    "  We are delighted to inform you that your KYC process is in-progress. "
                  ) : kycStatus === 'Not_Eligible' ?
                    <>
                      We are like to inform you that you are not allowed to complete the KYC.
                      <br />
                      <br />
                      {userDetails.kyc_reason ? userDetails.kyc_reason : ""}
                    </> : (
                      `To ensure the security and compliance of your account, we kindly
                    request you to complete your KYC (Know Your Customer)
                    verification. This process is quick and essential for
                    maintaining a safe and trusted environment for all our users.`
                    )}
                </div>

                {kycStatus === "Verified" ? (
                  <div className={Styles.kycText}>
                    Thank you for providing the necessary documentation and
                    information required for account verification. This step
                    ensures a secure and compliant experience for you as our
                    valued customer.
                  </div>
                ) : kycStatus === "Pending" ? (
                  <div className={Styles.kycText}>
                    Thank you for providing the necessary documentation and
                    information required for account verification. This step
                    ensures a secure and compliant experience for you as our
                    valued customer.
                  </div>
                ) : (
                  (!kycStatus || kycStatus === 'Require_Docs') &&
                  <button
                    onClick={startKyc}
                    className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} ${Styles.getStartedBtn} px-4 my-4`}>
                    Get Started
                  </button>
                )}
              </div>
            )}
            {activeTab === "Physical_Address" && (
              <div className={Styles.detailsCol}>
                <div className={Styles.textHeading}>Physical Address</div>

                <div
                  className={
                    "d-grid gap-4" +
                    (role !== "user" ? " col-12 col-md-10" : "")
                  }>
                  <div className="row gap-4 gap-md-0">
                    <div className="col-sm-12 col-md-6">
                      <InputField
                        value={
                          editAddress
                            ? userDetails.address
                            : fullAddress.address
                        }
                        type="text"
                        maxLength={50}
                        placeholder="Address"
                        name="Address1"
                        disabled={editAddress}
                        onChange={(e) => {
                          const isValid = /^[A-Za-z0-9_\- ]*$/.test(
                            e.target.value
                          );
                          if (isValid || e.target.value === "") {
                            setFullAddress({
                              ...fullAddress,
                              address: e.target.value.trimStart(),
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <InputField
                        value={
                          editAddress
                            ? userDetails.address2
                            : fullAddress.address2
                        }
                        type="text"
                        maxLength={50}
                        placeholder="Address 2"
                        height="50px"
                        name="Address2"
                        disabled={editAddress}
                        onChange={(e) => {
                          const isValid = /^[A-Za-z0-9_\- ]+$/.test(
                            e.target.value
                          );

                          if (isValid || e.target.value === "") {
                            setFullAddress({
                              ...fullAddress,
                              address2: e.target.value.trimStart(),
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row gap-4 gap-md-0">
                    <div className="col-sm-12 col-md-6">
                      {editAddress ? (
                        <InputField
                          value={userDetails.state}
                          type="text"
                          placeholder="State"
                          height="50px"
                          name="State"
                          disabled={true}
                        />
                      ) : (
                        <StateSelectField
                          value={fullAddress.state}
                          options={stateList}
                          type="text"
                          placeholder="State"
                          name="State"
                          disabled={editAddress}
                          defaultValue={editAddress && fullAddress.state ? undefined : userDetails.state}
                          onChange={(e) =>
                            setFullAddress({
                              ...fullAddress,
                              state: userDetails.state && userDetails.state === e.target.value.trimStart() ? '' : e.target.value.trimStart(),
                            })
                          }
                        />
                      )}
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <InputField
                        value={
                          editAddress ? userDetails.city : fullAddress.city
                        }
                        type="text"
                        maxLength={50}
                        placeholder="City"
                        height="50px"
                        name="City"
                        disabled={editAddress}
                        onChange={(e) => {
                          const isValid = /^[A-Za-z0-9_\- ]+$/.test(
                            e.target.value
                          );

                          if (isValid || e.target.value === "") {
                            setFullAddress({
                              ...fullAddress,
                              city: e.target.value.trimStart(),
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row gap-4 gap-md-0">
                    <div className="col-sm-12 col-md-6">
                      {editAddress ? (
                        <InputField
                          value={userDetails.country}
                          type="text"
                          placeholder="Country"
                          height="50px"
                          name="Country"
                          disabled={true}
                        />
                      ) : (
                        <CountrySelectField
                          value={fullAddress.country}
                          options={countryList}
                          type="text"
                          placeholder="Select Country"
                          name="Country"
                          disabled={editAddress}
                          defaultValue={editAddress && fullAddress.country ? undefined : userDetails.country}
                          onChange={(e) => {
                            setFullAddress({
                              ...fullAddress,
                              country: userDetails.country && userDetails.country === e.target.value.trimStart() ? '' : e.target.value.trimStart(),
                              state:
                                fullAddress.country === e.target.value
                                  ? e.target.value.trimStart()
                                  : "",
                            });
                            changeStates(e.target.value.trimStart());
                          }}
                        />
                      )}
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <InputField
                        value={editAddress ? userDetails.zip : fullAddress.zip}
                        type="text"
                        placeholder="Post Code"
                        name="Post Code"
                        maxLength={10}
                        // disabled={editAddress}
                        onChange={(e) => {
                          const isValid = /^[0-9]+$/.test(e.target.value);

                          if (isValid || e.target.value === "") {
                            setFullAddress({
                              ...fullAddress,
                              zip: e.target.value.trimStart()
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <button
                  disabled={isLoading}
                  onClick={() => {
                    if (editAddress) {
                      setEditAddress(false);
                      // getCountryList();
                      loadState();
                    } else {
                      if (isObjectEmpty(fullAddress)) {
                        setEditAddress(true);
                        return;
                      }
                      setShowdetailConfirmation(true)
                    }
                  }}
                  className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} ${Styles.updateBtn} px-2 my-4`}>
                  {!editAddress ? "Update" : "Edit"}
                </button>
              </div>
            )}
            {activeTab === "Update_Password" && (
              <ChangePasswordComponent role={role} />
            )}
            {activeTab === "Google_Authenticator" && (
              <div className={Styles.detailsCol}>
                <div className={Styles.textHeading}>Google Authenticator</div>
                <div className="d-grid gap-4">
                  <Switch
                    onColor="#fff"
                    isOn={isOn}
                    handleToggle={() => {
                      if (!isOn) {
                        showModal();
                      } else {
                        window.confirm(
                          "Are you sure you want to disable 2FA?"
                        ) && disableMFA();
                      }
                    }}
                  />
                  <div className="mt-4">
                    <p>
                      An Authenticator enhances the security of your online
                      accounts by requiring a secondary verification step during
                      sign-in. <br />
                      <br /> Alongside your password, you'll be prompted to
                      input a code generated by the Google Authenticator app on
                      your mobile device.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal size="lg" show={isOpen} onHide={() => hideModal(setIsOpen)}>
        <Modal.Body>
          <MFAModelComponent
            hide={() => hideModal(setIsOpen)}
            setIsOn={setIsOn}
          />
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={kycModal} onHide={() => hideModal(setKycModal)}>
        <Modal.Body>{
          kycStatus === 'Require_Docs' ?
            <AdditionalKYCDocModalComponent hide={() => hideModal(setKycModal)} />
            :
            <SumSubModalComponent hide={() => hideModal(setKycModal)} />
        }
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={showdetailConfirmation}>
        <Modal.Body>
          <DetailConfirmModalComponent
            changes={[{
              field: 'address',
              value: fullAddress.address
            },
            {
              field: 'address2',
              value: fullAddress.address2
            },
            {
              field: 'city',
              value: fullAddress.city
            },
            {
              field: 'state',
              value: fullAddress.state
            },
            {
              field: 'zip',
              value: fullAddress.zip
            },
            {
              field: 'country',
              value: fullAddress.country
            }]}
            handleConfirm={() => {
              setEditAddress(true);
              setShowdetailConfirmation(false)
              onAddressChangeConfirm()
            }} handleCancel={() => {
              onAddressChangeCancel()
              setShowdetailConfirmation(false)
            }}
          />
        </Modal.Body>
      </Modal>
      <ConfirmModalComponent
        show={confirmModal}
        onHide={() => hideModal(setConfirmModal)}
        handleConfirm={() => {
          update(confirmedData);
          setConfirmedData({});
          clearLocalStorage();
          dispatch(setUserLogout());
          navigate("/login");
        }}
        sendOTP={() => sendOTP(essInfo.email)}
        email={confirmedData.email}
      />
      <AddressWarningModalComponent
        show={addressModal}
        notice={showCountryChangeNotice}
        onClose={() => {
          setAddressModal(false);
        }}
      />
    </>
  );
};
export default ProfileDetailCard;
