// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_dashboardBody__Nx5KI {
  background: #00112b;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.dashboard_mainBody__tYh7l {
  padding: 2rem 3rem 3rem 2rem;
}
.dashboard_gap__CVFlm {
  row-gap: 2.5rem;
  /* column-gap: 0.00rem; */
  margin-bottom: 2rem;
  margin-top: 2.4rem;
}

.dashboard_mobileView__9CFev{
  display: none;
}

@media screen and (max-width: 767px) {
  .dashboard_mainBody__tYh7l {
    padding: 1rem 0rem 0rem 0rem;
  }
  .dashboard_gap__CVFlm {
    row-gap: 0rem;
    column-gap: 0rem;
  }
  /* .deskView{
    display: none;
  } */
  .dashboard_mobileView__9CFev{
    display: block;
  }
  .dashboard_investmentDiv__svEWq{
    padding-left: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/dashboard.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,+CAA+C;AACjD;;AAEA;EACE,4BAA4B;AAC9B;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,aAAa;IACb,gBAAgB;EAClB;EACA;;KAEG;EACH;IACE,cAAc;EAChB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".dashboardBody {\n  background: #00112b;\n  border-top: 1px solid rgba(255, 255, 255, 0.08);\n}\n\n.mainBody {\n  padding: 2rem 3rem 3rem 2rem;\n}\n.gap {\n  row-gap: 2.5rem;\n  /* column-gap: 0.00rem; */\n  margin-bottom: 2rem;\n  margin-top: 2.4rem;\n}\n\n.mobileView{\n  display: none;\n}\n\n@media screen and (max-width: 767px) {\n  .mainBody {\n    padding: 1rem 0rem 0rem 0rem;\n  }\n  .gap {\n    row-gap: 0rem;\n    column-gap: 0rem;\n  }\n  /* .deskView{\n    display: none;\n  } */\n  .mobileView{\n    display: block;\n  }\n  .investmentDiv{\n    padding-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardBody": `dashboard_dashboardBody__Nx5KI`,
	"mainBody": `dashboard_mainBody__tYh7l`,
	"gap": `dashboard_gap__CVFlm`,
	"mobileView": `dashboard_mobileView__9CFev`,
	"investmentDiv": `dashboard_investmentDiv__svEWq`
};
export default ___CSS_LOADER_EXPORT___;
