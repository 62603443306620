// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.token_tokenContainer__jr1hW {
    min-height: 13em;
    border-radius: 10px;
    background: #9c751a;
}
.token_tokenHeading__mPWs8{
    font-family: Poppins;
    font-size: 3em;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    word-wrap: break-word;

}
.token_cardImg__R8UHp{
    aspect-ratio: 8/4;
    object-fit: contain;
    object-position: center;

}


@media screen and (max-width: 767px){
    .token_tokenHeading__mPWs8{       
        font-size: 1.5em;
        font-weight: 600;    
    }
}
@media screen  and (max-width: 480px) {
    .token_tokenHeading__mPWs8{       
        font-size: 1.5em;
        font-weight: 600;    
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/TokenComponent/token.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,oBAAoB;IACpB,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;;AAEzB;AACA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;;AAE3B;;;AAGA;IACI;QACI,gBAAgB;QAChB,gBAAgB;IACpB;AACJ;AACA;IACI;QACI,gBAAgB;QAChB,gBAAgB;IACpB;AACJ","sourcesContent":[".tokenContainer {\n    min-height: 13em;\n    border-radius: 10px;\n    background: #9c751a;\n}\n.tokenHeading{\n    font-family: Poppins;\n    font-size: 3em;\n    font-weight: 600;\n    letter-spacing: 0em;\n    text-align: left;\n    word-wrap: break-word;\n\n}\n.cardImg{\n    aspect-ratio: 8/4;\n    object-fit: contain;\n    object-position: center;\n\n}\n\n\n@media screen and (max-width: 767px){\n    .tokenHeading{       \n        font-size: 1.5em;\n        font-weight: 600;    \n    }\n}\n@media screen  and (max-width: 480px) {\n    .tokenHeading{       \n        font-size: 1.5em;\n        font-weight: 600;    \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tokenContainer": `token_tokenContainer__jr1hW`,
	"tokenHeading": `token_tokenHeading__mPWs8`,
	"cardImg": `token_cardImg__R8UHp`
};
export default ___CSS_LOADER_EXPORT___;
