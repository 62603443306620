// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangePassword_detailsCol__Me5Rw {
  text-align: left;
  display: grid;
  gap: 22px;
  margin-left: 25px;
}

.ChangePassword_textHeading__CuCUm {
  /* width: 216px; */
  /* height: 14px; */
  /* margin: 39px 62px 34px 43.2px; */
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #00112b;
}

.ChangePassword_updateBtn__jMPri {
  width: fit-content;
}

@media screen and (max-width: 426px) {
  .ChangePassword_detailsCol__Me5Rw {
    margin: 0px;
  }
  .ChangePassword_textHeading__CuCUm {
    display: none;
  }
  .ChangePassword_updateBtn__jMPri {
    width: unset;
  }
  .ChangePassword_mobDNone__VHH9g {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/MyProfileComponent/ChangePasswordComponent/ChangePassword.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,mCAAmC;EACnC,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,aAAa;EACf;EACA;IACE,YAAY;EACd;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".detailsCol {\n  text-align: left;\n  display: grid;\n  gap: 22px;\n  margin-left: 25px;\n}\n\n.textHeading {\n  /* width: 216px; */\n  /* height: 14px; */\n  /* margin: 39px 62px 34px 43.2px; */\n  font-family: Poppins;\n  font-size: 20px;\n  font-weight: 600;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  text-align: left;\n  color: #00112b;\n}\n\n.updateBtn {\n  width: fit-content;\n}\n\n@media screen and (max-width: 426px) {\n  .detailsCol {\n    margin: 0px;\n  }\n  .textHeading {\n    display: none;\n  }\n  .updateBtn {\n    width: unset;\n  }\n  .mobDNone {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailsCol": `ChangePassword_detailsCol__Me5Rw`,
	"textHeading": `ChangePassword_textHeading__CuCUm`,
	"updateBtn": `ChangePassword_updateBtn__jMPri`,
	"mobDNone": `ChangePassword_mobDNone__VHH9g`
};
export default ___CSS_LOADER_EXPORT___;
