// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination_pagination__6Dh-O {
  width: auto !important;
  margin: auto;
  margin-top: 30px !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/MyOrder/Pagination/pagination.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,2BAA2B;AAC7B","sourcesContent":[".pagination {\n  width: auto !important;\n  margin: auto;\n  margin-top: 30px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `pagination_pagination__6Dh-O`
};
export default ___CSS_LOADER_EXPORT___;
