// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SupportPublicDoc_card__7zRaR {
  border-radius: 20px;
  background: #ebe3d1;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 2rem !important;
  text-align: left;
  height: 23rem;
}
.SupportPublicDoc_header__WGltX {
  color: rgba(33, 33, 33, 0.6);
  font-family: Aileron;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}
.SupportPublicDoc_body__2iaL1 {
  color: #505050;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  /* font-weight: 600; */
  /* line-height: normal; */
  margin-bottom: 0.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SupportComponent/SupportPublicDoc/SupportPublicDoc.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,gDAAgD;EAChD,wBAAwB;EACxB,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,4BAA4B;EAC5B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,sBAAsB;EACtB,yBAAyB;EACzB,qBAAqB;AACvB","sourcesContent":[".card {\n  border-radius: 20px;\n  background: #ebe3d1;\n  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);\n  padding: 2rem !important;\n  text-align: left;\n  height: 23rem;\n}\n.header {\n  color: rgba(33, 33, 33, 0.6);\n  font-family: Aileron;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  margin-bottom: 1rem;\n  margin-bottom: 1rem;\n}\n.body {\n  color: #505050;\n  font-family: Aileron;\n  font-size: 14px;\n  font-style: normal;\n  /* font-weight: 600; */\n  /* line-height: normal; */\n  margin-bottom: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `SupportPublicDoc_card__7zRaR`,
	"header": `SupportPublicDoc_header__WGltX`,
	"body": `SupportPublicDoc_body__2iaL1`
};
export default ___CSS_LOADER_EXPORT___;
