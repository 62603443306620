// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.passwordValidation_validationBlock__VedRQ {
  background-color: #fff;
  padding: 25px;
  display: none;
  gap: 12px;

  flex-grow: 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: left;
  color: #2d333a;

  z-index: 1;
}

.passwordValidation_validationList__318Eu {
  display: flex;
  align-items: center;
  gap: 10px;

  flex-grow: 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: left;
  color: #00112b;
}

@media screen and (max-width: 426px) {
  .passwordValidation_imgIcon__1tcmn {
    width: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/PasswordInputField/PasswordValidation/passwordValidation.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,SAAS;;EAET,YAAY;EACZ,oBAAoB;EACpB,eAAe;EACf,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,cAAc;;EAEd,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;;EAET,YAAY;EACZ,oBAAoB;EACpB,eAAe;EACf,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".validationBlock {\n  background-color: #fff;\n  padding: 25px;\n  display: none;\n  gap: 12px;\n\n  flex-grow: 0;\n  font-family: Poppins;\n  font-size: 14px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.43;\n  letter-spacing: 0.25px;\n  text-align: left;\n  color: #2d333a;\n\n  z-index: 1;\n}\n\n.validationList {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n\n  flex-grow: 0;\n  font-family: Poppins;\n  font-size: 14px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.43;\n  letter-spacing: 0.25px;\n  text-align: left;\n  color: #00112b;\n}\n\n@media screen and (max-width: 426px) {\n  .imgIcon {\n    width: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"validationBlock": `passwordValidation_validationBlock__VedRQ`,
	"validationList": `passwordValidation_validationList__318Eu`,
	"imgIcon": `passwordValidation_imgIcon__1tcmn`
};
export default ___CSS_LOADER_EXPORT___;
