// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddWalletModelComponent_header__PXX6- {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  text-align: start;
}
.AddWalletModelComponent_body__zBWyH {
  color: #212121;
  font-family: Aileron;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 2rem 0em;
}
`, "",{"version":3,"sources":["webpack://./src/Components/PurchaseComponent/AddWalletModelComponent/AddWalletModelComponent.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".header {\n  color: #212121;\n  font-family: Desire-Pro;\n  font-size: 50px;\n  font-style: normal;\n  font-weight: 400;\n  text-align: start;\n}\n.body {\n  color: #212121;\n  font-family: Aileron;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  margin: 2rem 0em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `AddWalletModelComponent_header__PXX6-`,
	"body": `AddWalletModelComponent_body__zBWyH`
};
export default ___CSS_LOADER_EXPORT___;
